import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/game/Popups.css';

const difficulties = ['Easy', 'Medium', 'Hard'];

function AllGuessedPopup({ difficulty, onClose, onRestart, onChangeDifficulty }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    // Filter out the current difficulty from the list
    const availableDifficulties = difficulties.filter(d => d && difficulty && d.toLowerCase() !== difficulty.toLowerCase());

    return (
        <div className="popup-overlay">
            <div className="allGuessed-content">
                <div className="msgCont">
                    <h6>All animals in {difficulty} difficulty are guessed or skipped!</h6>
                </div>
                <img className="animalGif" src="https://31.media.tumblr.com/498e3ba0edbe1f56b3acd1f846952710/tumblr_msvgpxjn121srkue0o1_500.gif" alt="congrats"/>
                <div>Do you want to continue in {difficulty} difficulty? Or do you want to change?</div>
                <div className="difficultyBtnCont">
                    <Link to={"/"} style={{ textDecoration: 'none' }}>
                        <button className="popupBtn">Main Menu</button>
                    </Link>
                    <button className="popupBtn" onClick={onRestart}>Restart</button>
                    <div className="Gdropdown">
                        <button className="popupBtn" onClick={toggleDropdown}>Change Difficulty</button>
                        {dropdownOpen && (
                            <div className="Gdropdown-content show">
                                {availableDifficulties.map(d => (
                                    <button key={d} onClick={() => onChangeDifficulty(d)}>
                                        {d}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllGuessedPopup;
