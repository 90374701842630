import React, { useEffect } from 'react';
import '../styles/game/Popups.css'
import { Link } from 'react-router-dom';

function AnimalPopup({ message, imageUrl, onClose, onNewGame, animalSummary, guessCount, 
    timer, animalNames, animalClass, animalOrder, animalFamily, animalSci, animalConservationStatus }) {

        const formatTime = () => {
            if (typeof timer === 'number') {
                const minutes = Math.floor(timer / 60);
                const seconds = timer % 60;
                return `${minutes}:${seconds.toString().padStart(2, '0')}`;  // Format as "M:SS"
            } else {
                return timer; // Directly return the timer if it's not a number (e.g., "Gave up")
            }
        };

            // Use useEffect to add/remove class to body
    useEffect(() => {
        document.body.classList.add('no-scroll');
        
        // Cleanup function to remove class when component unmounts or updates
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);  // Empty dependency array to run only on mount and unmount

    const statusStyles = {
        "Critically Endangered": { backgroundColor: '#5c1010', color: 'white' },
        "Endangered species": { backgroundColor: '#AD3E33', color: 'white' },
        "Vulnerable": { backgroundColor: '#D47830', color: 'white' },
        "Near Threatened": { backgroundColor: '#FFDB0A', color: 'black' },
        "Least Concern": { backgroundColor: '#5AA220', color: 'white' },
        "extinct species": {backgroundColor: 'transparent', color: 'transparent'}
    };

    


    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <div className="statOverview">
                    <div><img className="catImg2" src="/catIcons/timer.svg" alt="time icon"/> Time: {formatTime()}</div>
                    <div>Guesses: {guessCount} <img className="catImg3" src="/catIcons/guesses.svg" alt="guesses icon"/></div>
                </div>
                <div className="msgCont">
                    <h6>{message}</h6>
                    <h1>{animalNames[0]}</h1>
                </div>
                <div className="popupIMGnTex">
                    {imageUrl && <img src={imageUrl} alt="Animal" className="animal-image" />}

                    <div className="underImg">
                        <div className="scientific"><i>{animalSci}</i></div>

                        <div className="conservationStatus" style={statusStyles[animalConservationStatus]}>
                            {animalConservationStatus}
                        </div>
                        
                        <div className="taxPop">
                            <img className="taxImgPop" alt="animal class" src={`/dropdownIcons/${animalClass.toLowerCase()}1.svg`} />
                            <img className="taxImgPop" alt="animal order" src={`/dropdownIcons/${animalOrder.toLowerCase()}1.svg`} />
                            <img className="taxImgPop" alt="animal family" src={`/dropdownIcons/${animalFamily.toLowerCase()}1.svg`} />
                        </div>
                    </div>

                    <div className="animalInfo" dangerouslySetInnerHTML={{__html: animalSummary}}/>
                </div>
                <div className= "popupBtnCont">
                    <Link to={"/"} style={{ textDecoration: 'none' }}>
                        <button className="popupBtn">Main Menu</button>
                    </Link>
                    <button className="popupBtn" onClick={onNewGame}>New Game</button>
                </div>
            </div>
        </div>
    );
}

export default AnimalPopup;