import React, { useState } from 'react';
import Dropdown from './Dropdown';
import WeightDropdown from './WeightDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faClock, faClockFour, faEarthEurope, faPalette, faSitemap } from '@fortawesome/free-solid-svg-icons';
import AutocompleteInput from './AutocompleteInput';
import animals from '../animals.json';

function BottomRow({
    continents, openDropdown, setOpenDropdown, handleGuess, guessedContinents, sessionAnimalContinents,
    habitatTypes, correctHabitatType, habitats, guessedHabitats, sessionAnimalHabitats,
    classData, correctClass, orderData, correctOrder, familyData, correctFamily,
    diet, groupBehavior, lifestyles, domesticated, colors, correctDiet, userGuess, setUserGuess, 
    handleNameGuess, handleKeyPress, sessionAnimalColors, guessedColors, guessedHabitatType, sessionAnimalHabitatTypes, 
    sessionGroupBehavior, correctGroupBehavior, correctLifestyles, sessionLifestyles, hasMostColors, setGuessMessage,
    guessCount, timer, handleGiveUpClick, inputRef, wrongGuesses,setWeightUnit, tempInputValue, setTempInputValue
}) {

    const [isWeightCorrect, setIsWeightCorrect] = useState(false);

    const handleGuessWrapper = async (type, value) => {
        const result = await handleGuess(type, value);
        if (type === 'weight' && result.includes("Correct")) {
            setIsWeightCorrect(true);
        }
    };

    const formatTime = () => {
        const minutes = Math.floor(timer / 60);
        const seconds = timer % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;  // Format as "M:SS"
    };

    const sortItems = (items) => items.sort((a, b) => a.localeCompare(b));

    const colorCircles = {
        Black: ['#000000'],
        Blue: ['#1053ba', '#598bae', '#74c2fc'],
        Brown: ['#654320', '#682401', '#362611'],
        Gray: ['#d3d3d3', '#595959'],
        Green: ['#708138', '#228c22', '#2d471c'],
        Orange: ['#ed7014', '#f28e1c'],
        Pink: ['#ff91a4', '#ff9989', '#f05277'],
        Red: ['#c04000', '#d2322e', '#8a0707'],
        Tan: ['#d2b48c', '#ffd59a', '#928150'],
        White: ['white', '#fdf6e4', '#ecfbfc'],
        Yellow: ['#ffdf00', '#fff44f']
        
        // Add more colors and their circles as needed
    };

    

    return (
        <div className="bottom-row">
            <div className="stratMobileCont">
                <div className="statText2"><img className="catImg2" src="/catIcons/timer.svg" alt="time icon"/>{formatTime()}</div>
                <div className="statText2">{guessCount}<img className="catImg3" src="/catIcons/guesses.svg" alt="guesses icon"/></div>
            </div>
            <div className="inputCont">
                <div className="statText"><img className="catImg2" src="/catIcons/timer.svg" draggable="false" alt="time icon"/>{formatTime()}</div>
                <div className="inputArea">
                    <button className="GiveupBtn" onClick={() => handleGiveUpClick()}>Give up?</button>
                    <AutocompleteInput
                        suggestions={animals}
                        userGuess={userGuess}
                        setUserGuess={setUserGuess}
                        handleKeyPress={handleKeyPress}
                        inputRef={inputRef}
                        tempInputValue={tempInputValue}
                        setTempInputValue={setTempInputValue}
                        handleNameGuess={handleNameGuess}
                    />
                </div>
                <div className="statText">{guessCount}<img className="catImg3" src="/catIcons/guesses.svg" draggable="false" alt="guesses icon"/></div>
            </div>
            {wrongGuesses.length > 0 && (
                <div className="wrongGuessCont">
                    <div className="wrongGuesses">
                        Guessed: {wrongGuesses.join(', ')}
                    </div>
                </div>
            )}
            <div className="bot-cont">
                <div className="category">
                    <div className="catHeadings"><img className="catImg" src="/catIcons/geography.svg" draggable="false" alt="geography icon"/><h2>Geography</h2></div>
                    <div className="btnMenu">
                        <Dropdown title="Continents" items={sortItems(continents)} isOpen={openDropdown} setIsOpen={setOpenDropdown} onSelect={(item) => handleGuess('continent', item)} disabled={continents.length === 0 || guessedContinents.filter(guess => guess !== "Unknown").length === sessionAnimalContinents.length || sessionAnimalContinents.includes('Global')} />
                        <Dropdown title="Habitat Types" items={sortItems(habitatTypes.map(ht => ht.name))} isOpen={openDropdown} setIsOpen={setOpenDropdown} onSelect={(item) => handleGuess('habitatType', item)} disabled={habitatTypes.length === 0 || correctHabitatType.length === sessionAnimalHabitatTypes.length} />
                        <Dropdown title="Habitats" items={sortItems(habitats)} isOpen={openDropdown} setIsOpen={setOpenDropdown} onSelect={(item) => handleGuess('habitat', item)} disabled={habitats.length === 0 || guessedHabitats.filter(guess => guess !== "Unknown").length === sessionAnimalHabitats.length} />
                    </div>
                </div>
                <div className="category">
                    <div className="catHeadings"><img className="catImg" src="/catIcons/taxonomy.svg" draggable="false" alt="taxonomy icon"/><h2>Taxonomy</h2></div>
                    <div className="btnMenu">
                        <Dropdown 
                            title="Class" 
                            items={sortItems(classData ? classData.map(c => c.name) : [])} 
                            isOpen={openDropdown} 
                            setIsOpen={setOpenDropdown} 
                            onSelect={(item) => handleGuess('class', item)} 
                            disabled={classData.length === 0 || correctClass || correctOrder || correctFamily} />
                        <Dropdown title="Order" items={sortItems(orderData ? orderData.map(o => o.name) : [])} isOpen={openDropdown} setIsOpen={setOpenDropdown} onSelect={(item) => handleGuess('order', item)} disabled={orderData.length === 0 || correctOrder || correctFamily} />
                        <Dropdown title="Family" items={sortItems(familyData)} isOpen={openDropdown} setIsOpen={setOpenDropdown} onSelect={(item) => handleGuess('family', item)} disabled={familyData.length === 0 || correctFamily} />
                    </div>
                </div>
                <div className="category">
                    <div className="catHeadings"><img className="catImg" src="/catIcons/behavior.svg" draggable="false" alt="behavior icon"/><h2>Behavior</h2></div>
                    <div className="btnMenu">
                        <Dropdown title="Diet" items={sortItems(diet.map(d => d.name))} isOpen={openDropdown} setIsOpen={setOpenDropdown} onSelect={(item) => handleGuess('diet', item)} disabled={diet.length === 0 || correctDiet} />
                        <Dropdown title="Group Behavior" items={sortItems(groupBehavior)} isOpen={openDropdown} setIsOpen={setOpenDropdown} onSelect={(item) => handleGuess('groupBehavior', item)} disabled={groupBehavior.length === 0 || correctGroupBehavior.length === sessionGroupBehavior.length} />
                        <Dropdown title="Lifestyles" items={sortItems(lifestyles)} isOpen={openDropdown} setIsOpen={setOpenDropdown} onSelect={(item) => handleGuess('lifestyle', item)} disabled={lifestyles.length === 0 || correctLifestyles.length === sessionLifestyles.length} />
                        <Dropdown title="Domesticated" items={sortItems(domesticated)} isOpen={openDropdown} setIsOpen={setOpenDropdown} onSelect={(item) => handleGuess('domesticated', item)} disabled={domesticated.length === 1} />
                    </div>
                </div>
                <div className="category">
                    <div className="catHeadings"><img className="catImg" src="/catIcons/attributes.svg" draggable="false" alt="attributes icon"/><h2>Attributes</h2></div>
                    <div className="btnMenu">
                    <Dropdown 
                            title="Colors" 
                            items={sortItems(colors)} 
                            isOpen={openDropdown} 
                            setIsOpen={setOpenDropdown} 
                            onSelect={(item) => handleGuess('color', item)} 
                            disabled={colors.length === 0 || guessedColors.filter(color => color !== null).length === sessionAnimalColors.length || hasMostColors} 
                            colorCircles={colorCircles} 
                        />
                        <WeightDropdown title="Weight" onSelect={(item) => handleGuessWrapper('weight', item)} disabled={isWeightCorrect} setWeightUnit={setWeightUnit} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BottomRow;