import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

function WeightDropdown({ title, onSelect, disabled, setWeightUnit }) {
    const [isOpen, setIsOpen] = useState(false);
    const [weight, setWeight] = useState('');
    const [unit, setUnit] = useState('kg');
    const dropdownRef = useRef(null); // Reference to the dropdown container

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const handleGuess = () => {
        if (weight) {
            const weightNumber = parseFloat(weight);
            const weightInKg = unit === 'lbs' ? weightNumber / 2.20462 : weightNumber;
            onSelect(weightInKg.toFixed(2));
            setWeightUnit(unit);
            setIsOpen(false);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleGuess();
        }
    };

    return (
        <div className="btnNlistCont" ref={dropdownRef}>
            <button
                onClick={() => !disabled && setIsOpen(!isOpen)}
                className="dropdown-button"
                disabled={disabled}
                style={{
                    backgroundColor: disabled ? 'gray' : '',
                    color: disabled ? 'lightgray' : 'black',
                    cursor: disabled ? 'not-allowed' : 'pointer',
                }}
            >
                {title} <FontAwesomeIcon className="chevron" icon={faAngleDown} />
            </button>
            {isOpen && !disabled && (
                <div className="weight-menu">
                    <div className="weight-menu-input">
                        <input
                            type="number"
                            value={weight}
                            onChange={(e) => setWeight(e.target.value)}
                            onKeyDown={handleKeyPress}
                            placeholder="Enter weight"
                            className="weightInput"
                        />
                        <div className="weightRadioCont"> 
                            <label className="weightLabel">
                                <input
                                    type="radio"
                                    value="kg"
                                    checked={unit === 'kg'}
                                    onChange={() => setUnit('kg')}
                                />
                               kg
                            </label>
                            <label className="weightLabel">
                                <input
                                    type="radio"
                                    value="lbs"
                                    checked={unit === 'lbs'}
                                    onChange={() => setUnit('lbs')}
                                />
                                lbs
                            </label>
                        </div>
                        <button className="weightBtn" onClick={handleGuess}>Guess</button>
                    </div>
                    <div className="weightText">1+ for kg, 0.1 for 100 grams, 0.01 for 10 grams etc.</div>
                    <div className="weightText">If you guess a correct weight, you will get the animal's weight range.</div>
                </div>
            )}
        </div>
    );
}

export default WeightDropdown;
