import React, { useState, useEffect } from 'react';
import '../styles/other/Other.css'
import { Link } from 'react-router-dom';
import Footer from "../components/Footer.jsx";
import { Helmet } from 'react-helmet';
import NavBar from '../components/NavBar.jsx'

function About() {
    return(
      <div>
        <Helmet>
                <title>About - Wildguesser</title>
                <meta name="description" content="Learn more about WildGuesser, our mission, and our commitment to wildlife education and conservation." />
            
                {/*<!-- Open Graph / Facebook -->*/}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://wildguesser.com/about"/>
                <meta property="og:title" content="About - Wildguesser"/>
                <meta property="og:description" content="Learn more about WildGuesser, our mission, and our commitment to wildlife education and conservation."/>
                <meta property="og:image" content="logoetc/logofull.png"/>

                {/*<!-- Twitter -->*/}
                <meta property="twitter:card" content="logoetc/logofull.png"/>
                <meta property="twitter:url" content="https://wildguesser.com/about"/>
                <meta property="twitter:title" content="About - Wildguesser"/>
                <meta property="twitter:description" content="Learn more about WildGuesser, our mission, and our commitment to wildlife education and conservation."/>
                <meta property="twitter:image" content="logoetc/logofull.png"/>   
            
            </Helmet>
        <NavBar/>
        <div className="ToSbody">
          
            
            
            <h1>About Us</h1>

<h2>Welcome to WildGuesser!</h2>
<p>
  Welcome to WildGuesser! We're thrilled to introduce you to a fun and exciting guessing game designed for animal lovers of all ages and skill levels. Whether you're just beginning your journey into the animal kingdom or are already an expert, WildGuesser offers a delightful way to test your knowledge and learn more about the diverse world of animals.
</p>

<h2>Our Mission</h2>
<p>
  We are deeply passionate about wildlife and want to share that passion with you. Our goal is to create an engaging and interactive game where you can not only test your animal knowledge but also discover fascinating facts and contribute to wildlife protection efforts.
</p>

<h2>Our Commitment</h2>
<h3>Educational Fun</h3>
<p>
  At WildGuesser, we believe that learning should be enjoyable. We strive to make our game both fun and informative, ensuring that players of all ages can delight in learning about animals in an engaging way.
</p>
<h3>Community and Conservation</h3>
<p>
  We are committed to building a community of animal lovers who are dedicated to making a positive impact on the world. Our game is more than just a pastime; it’s a platform for raising awareness about wildlife conservation. Together, we can support critical conservation efforts and help protect the animals we care about.
</p>
<h3>Continuous Improvement</h3>
<p>
  We are dedicated to continuously enhancing the WildGuesser experience. This includes adding new features, game modes, and content regularly to keep the game fresh, exciting, and educational.
</p>

<h2>Join Us</h2>
<p>
  We invite you to join the WildGuesser community! Together, we can spread awareness and foster a deeper appreciation for the animal kingdom, one guess at a time. By playing WildGuesser, you become part of a movement dedicated to learning about and protecting wildlife.
</p>

<h2>How to Get Involved</h2>
<ul>
  <li><strong>Play the Game</strong>: Dive into WildGuesser and challenge yourself with different game modes. Test your knowledge and learn new facts about animals.</li>
  <li><strong>Share Your Knowledge</strong>: Engage with our community by sharing your favorite animal facts and tips for playing the game.</li>
  <li><strong>Support Conservation Efforts</strong>: Learn about and support wildlife conservation initiatives through our platform. Every guess helps raise awareness.</li>
  <li><strong>Stay Updated</strong>: Keep an eye out for new updates and features. We're constantly improving WildGuesser to provide you with the best experience possible.</li>
</ul>

<h2>Contact Us</h2>
<p>
  If you have any questions, feedback, or suggestions, we’d love to hear from you. Connect with us through our social media channels or send us an email. Your input is invaluable in helping us grow and improve WildGuesser.
</p>

<p>
  Thank you for being part of our community. Together, let's make a difference and celebrate the wonders of the animal kingdom!
</p>
      
   



      <Footer />
        </div>
        
        </div>

    );
}

export default About;