import React from 'react';

const GiveUpConfirmation = ({ onClose, onConfirm }) => (
    <div className="popup-overlay">
    <div className="GiveUpPopup">

            <h6>Are you sure you want to give up?</h6>
            <div className="guButtonCont">
                <button className="guButton" onClick={onConfirm} style={{backgroundColor: "#ff9f8a"}}>
                    Yes
                    </button>
                <button className="guButton" onClick={onClose} style={{backgroundColor: "#a0d39c"}}>
                    No
                    </button>
            </div>

        </div>
    </div>
);

export default GiveUpConfirmation;
