import React, { useState, useEffect, useRef } from 'react';
import '../styles/game/suggestions.css';

function AutocompleteInput({
  suggestions,
  userGuess,
  setUserGuess,
  inputRef,
  handleKeyPress,
  tempInputValue,
  setTempInputValue,
  handleNameGuess,
}) {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputFocusClass, setInputFocusClass] = useState('');
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  //const [tempInputValue, setTempInputValue] = useState(userGuess);
  const blurTimeoutRef = useRef();
  const enterPressedRef = useRef(false);
  const clickPressedRef = useRef(false);
  const [shouldGuess, setShouldGuess] = useState(false);
  const [temporaryGuess, setTemporaryGuess] = useState('');

  useEffect(() => {
    if (userGuess.length >= 2) {
      let filtered = Object.entries(suggestions).reduce((acc, [key, values]) => {
        if (key.toLowerCase().includes(userGuess.toLowerCase()) || values.some(val => val.toLowerCase().includes(userGuess.toLowerCase()))) {
          acc.push({ name: key, score: calculateSimilarityScore(key, userGuess) });
        }
        return acc;
      }, []);

      // Sort suggestions based on similarity score
      filtered.sort((a, b) => b.score - a.score);
      setShowSuggestions(filtered.length > 0);
      setFilteredSuggestions(filtered.map(item => item.name));
      setActiveSuggestion(-1); // Reset active suggestion
    } else {
      setShowSuggestions(false);
    }
  }, [userGuess, suggestions]);

  function calculateSimilarityScore(suggestion, input) {
    const inputLower = input.toLowerCase();
    const suggestionLower = suggestion.toLowerCase();
    const words = suggestionLower.split(' ');
  
    let score = 0;
  
    words.forEach((word, index) => {
      if (word.startsWith(inputLower)) {
        // Highest score for matches at the start of the word, weighted by position
        score += 1000 / (index + 1);
      } else if (word.includes(inputLower)) {
        // Lower score for matches within the word, weighted by position
        score += 100 / (index + 1);
      }
    });
  
    return score;
  }

  const handleFocus = () => {
    // Add a specific class only if there are suggestions to show
    setInputFocusClass(filteredSuggestions.length > 0 ? 'focus-with-options' : '');
  };

  const handleBlur = () => {
    // Set timeout to delay blur action allowing for click event to process
    blurTimeoutRef.current = setTimeout(() => {
      setShowSuggestions(false);
      
    }, 100);  // Delay can be adjusted as needed
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      if (activeSuggestion === filteredSuggestions.length - 1) {
        setActiveSuggestion(-1);
        setTempInputValue(userGuess);
      } else {
        setActiveSuggestion((prev) => prev + 1);
      }
      e.preventDefault();
    } else if (e.key === 'ArrowUp') {
      if (activeSuggestion === -1) {
        setActiveSuggestion(filteredSuggestions.length - 1);
        setTempInputValue(filteredSuggestions[filteredSuggestions.length - 1]);
      } else if (activeSuggestion === 0) {
        setActiveSuggestion(-1);
        setTempInputValue(userGuess);
      } else {
        setActiveSuggestion((prev) => prev - 1);
      }
      e.preventDefault();
    } else if (e.key === 'Enter') {
      if (activeSuggestion >= 0) {
        const selectedSuggestion = filteredSuggestions[activeSuggestion];
        setUserGuess(selectedSuggestion);
        setTempInputValue(selectedSuggestion);
        setShowSuggestions(false);
        enterPressedRef.current = true;
      } else {
        handleKeyPress(e); // Trigger the guess action if no suggestion is selected
      }
      e.preventDefault();  // Prevent form submission if within a form
    }
  };

  useEffect(() => {
    if (enterPressedRef.current) {
      handleKeyPress({ key: 'Enter', target: { value: userGuess } });
      enterPressedRef.current = false;
    }
    if (clickPressedRef.current) {
      handleKeyPress({ key: 'Enter', target: { value: userGuess } });
      clickPressedRef.current = false;
    }
  }, [userGuess]);

  useEffect(() => {
    if (showSuggestions && activeSuggestion >= 0 && filteredSuggestions.length > 0) {
      setTempInputValue(filteredSuggestions[activeSuggestion]);
    } else {
      setTempInputValue(userGuess);
    }
  }, [activeSuggestion, filteredSuggestions, showSuggestions, userGuess]);

  const handleChange = (e) => {
    setUserGuess(e.target.value);
    setTempInputValue(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setUserGuess(suggestion);
    setTempInputValue(suggestion);
    setShowSuggestions(false);
    clickPressedRef.current = true;
  
  };

  const handleGuessButtonClick = () => {


    if (activeSuggestion >= 0 && filteredSuggestions.length > 0) {
        const selectedSuggestion = filteredSuggestions[activeSuggestion];
        setUserGuess(selectedSuggestion);
        setTemporaryGuess(selectedSuggestion);
        setShouldGuess(true);
    } else {
        setUserGuess(tempInputValue);
        setTemporaryGuess(tempInputValue);
        setShouldGuess(true);
    }
};

useEffect(() => {
  if (shouldGuess && userGuess === temporaryGuess) {
      handleNameGuess(userGuess);
      setShouldGuess(false); // Reset the trigger
  }
}, [shouldGuess, userGuess, temporaryGuess]);

  return (
    <div className="inputNsuggestions">
      <input
        ref={inputRef}
        type="text"
        className={`guessInput ${inputFocusClass}`}
        placeholder="Guess your animal here...."
        value={tempInputValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        style={{
          borderBottomLeftRadius: showSuggestions ? '0' : ''
        }}
      />
      {showSuggestions && (
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => (
            <li
              key={index}
              className={index === activeSuggestion ? 'active' : ''}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
      <button className="guessBtn" onClick={handleGuessButtonClick}>Guess</button>
    </div>
  );
}

export default AutocompleteInput;
