// HowToPlay.js
import React, { useState } from 'react';
import '../styles/Home/Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const HowToPlay = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="howToPlayCont">
            <div className="circleCont" onClick={toggleDropdown}>
                <FontAwesomeIcon className="circleQ" icon={faCircleQuestion} />
            </div>
            {isOpen && (
                <div className="infoCont2">
                    <div className="infoHeading"><h2>How to play</h2></div>
                    <div className="infoContentWrapper">
                        <div className="infoContentCont">
                            <p><b>This is how you play the game:</b> </p>
                            <p>1. <b>Click</b> on a dropdown button:</p>
                            <img src="/tutorial/click.png" alt="click" className="tutorialImg"></img>
                            <p>2. <b>Choose</b> an option from the dropdown list:</p>
                            <img src="/tutorial/choose.png" alt="choose" className="tutorialImg"></img>
                            <p>3. If your guess is correct, it will appear on the relevant <b>card</b>:</p>
                            <img src="/tutorial/card.png" alt="card" className="tutorialImg"></img>
                            <p>4. When you have enough information about the animal you can guess its name using the <b>guess-input</b>:</p>
                            <img src="/tutorial/guess.png" alt="guess" className="tutorialImg2"></img>
                            <i className="guessinfo">Here the user guessed "Dog".</i>

                            <h3>Tips for playing:</h3>
                            <b>Guess taxonomy in order:</b>

                            <p><b>1. Guess the Class First:</b> Start by guessing the class. This will filter the possible orders and 
                                families to only those within the guessed class.
                            </p>
                            <p><b>2. Guess the Order Next:</b> After identifying the class, guess the order. This will further narrow 
                                down the families to those within the guessed order.
                            </p>
                            <p><b>3. Guess the Family Last:</b> Finally, guess the family. With the previous filters in place, you're 
                                more likely to make a correct guess.
                            </p>
                            <p>By following this sequence—class, order, then family—you increase your chances of making accurate guesses.</p>
                            <p style={{color: '#a0d39c'}}><Link to={"/guide"} style={{ textDecoration: 'underline', color: '#a0d39c' }} target="_blank">Click here for a more in depth guide</Link></p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HowToPlay;
