import React, { useState, useEffect } from 'react';
import '../styles/other/Other.css'
import { Link } from 'react-router-dom';
import Footer from "../components/Footer.jsx";
import { Helmet } from 'react-helmet';
import NavBar from '../components/NavBar.jsx'

function AnimalFacts() {
    return(
      <div>
        <Helmet>
                <title>Animal Facts - Wildguesser</title>
                <meta name="description" content="Discover fascinating animal facts and learn about the incredible diversity of wildlife. From the blue whale's massive heart to the chameleon's lightning-fast tongue, explore the unique characteristics and amazing abilities of animals around the world." />
            
                {/*<!-- Open Graph / Facebook -->*/}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://wildguesser.com/facts"/>
                <meta property="og:title" content="Animal Facts - Wildguesser"/>
                <meta property="og:description" content="Discover fascinating animal facts and learn about the incredible diversity of wildlife. From the blue whale's massive heart to the chameleon's lightning-fast tongue, explore the unique characteristics and amazing abilities of animals around the world."/>
                <meta property="og:image" content="logoetc/logofull.png"/>

                {/*<!-- Twitter -->*/}
                <meta property="twitter:card" content="logoetc/logofull.png"/>
                <meta property="twitter:url" content="https://wildguesser.com/facts"/>
                <meta property="twitter:title" content="Animal Facts - Wildguesser"/>
                <meta property="twitter:description" content="Discover fascinating animal facts and learn about the incredible diversity of wildlife. From the blue whale's massive heart to the chameleon's lightning-fast tongue, explore the unique characteristics and amazing abilities of animals around the world."/>
                <meta property="twitter:image" content="logoetc/logofull.png"/>   
            
            </Helmet>
        <NavBar/>
        <div className="ToSbody">
          
            
            
            <h1>Fascinating Animal Facts</h1>

            <p>Welcome to our compilation of intriguing animal facts! The animal kingdom is full of wonders, and there’s always something 
                new to learn about the creatures we share our planet with. Here are some fascinating tidbits that highlight the incredible 
                diversity and unique characteristics of animals from around the world.
            </p>

            <h2>1. The Heart of a Blue Whale</h2>
            <div className="factsDiv">
                <img src="educational/elephant.jpg" alt="elephant" className="factsImg"/>
                <div className="factsText">
                    
                    <p>The blue whale, the largest animal on Earth, has a heart that can weigh as much as a small car (around 1,300 pounds or 590 kilograms). This massive heart beats slowly, about 8 to 10 times per minute, and its arteries are so large that a human could swim through them.</p>
                </div>
            </div>

            <h2>2. Octopus Intelligence</h2>
            <div className="factsDiv">
                <img src="educational/octopus.jpg" alt="octopus" className="factsImg"/>
                <div className="factsText">
                    
                    <p>Octopuses are incredibly intelligent and exhibit behaviors such as problem-solving, using tools, and escaping from enclosures. Each of their eight arms contains its own neurons, allowing them to perform complex movements independently.</p>
                </div>
            </div>

            <h2>3. The Mimic Octopus</h2>
            <div className="factsDiv">
                <img src="educational/mimicocto.jpg" alt="mimic octopus" className="factsImg"/>
                <div className="factsText">
                    
                    <p>Speaking of octopuses, the mimic octopus is a master of disguise. Found in the Indo-Pacific, it can imitate the shapes and movements of other sea creatures, such as lionfish, flatfish, and sea snakes, to avoid predators.</p>
                </div>
            </div>

            <h2>4. Tardigrades: The Ultimate Survivors</h2>
            <div className="factsDiv">
                <img src="educational/tardigrade.webp" alt="tardigrade" className="factsImg"/>
                <div className="factsText">
                    
                    <p>Tardigrades, also known as water bears, are microscopic animals that can survive extreme conditions, including the vacuum of space, intense radiation, and temperatures ranging from nearly absolute zero to over 300 degrees Fahrenheit (150 degrees Celsius).</p>
                </div>
            </div>


            
            <h2>5. The Sleep Habits of Giraffes</h2>
            <div className="factsDiv">
                <img src="educational/giraffe.jpg" alt="giraffe" className="factsImg"/>
                <div className="factsText">
                    
                    <p>Giraffes have one of the shortest sleep requirements of any mammal, often sleeping for only 5 to 30 minutes in a 24-hour period. They usually sleep standing up and only lie down for short periods.</p>
                </div>
            </div>

            <h2>6. Dolphins Have Names</h2>
            <div className="factsDiv">
                <img src="educational/dolphin.jpg" alt="dolphin" className="factsImg"/>
                <div className="factsText">
                    
                    <p>Dolphins are known to use unique whistles that function like names. Each dolphin has its own signature whistle, which allows them to identify and communicate with each other individually.</p>
                </div>
            </div>

            
            <h2>7. Axolotl Regeneration</h2>
            <div className="factsDiv">
                <img src="educational/axolotl.jpg" alt="axolotl" className="factsImg"/>
                <div className="factsText">
                    
                    <p>The axolotl, a type of salamander, has remarkable regenerative abilities. It can regrow entire limbs, parts of its spine, heart, and even sections of its brain without any scarring.</p>
                </div>
            </div>

            <h2>8. The Monarch Butterfly Migration</h2>
            <div className="factsDiv">
                <img src="educational/monarch.jpg" alt="monarch butterfly" className="factsImg"/>
                <div className="factsText">
                    
                    <p>Monarch butterflies undertake one of the most incredible migrations in the insect world. They travel up to 3,000 miles from North America to central Mexico to escape the cold winter. This journey spans multiple generations, with no single butterfly completing the round trip.</p>
                </div>
            </div>

            <h2>9. The Electric Eel</h2>
            <div className="factsDiv">
                <img src="educational/electric.jpg" alt="electric eel" className="factsImg"/>
                <div className="factsText">
                    
                    <p>Despite its name, the electric eel is not a true eel but a type of knifefish. It can generate powerful electric shocks of up to 600 volts, which it uses for hunting and self-defense.</p>
                </div>
            </div>

            <h2>10. Echolocation in Bats</h2>
            <div className="factsDiv">
                <img src="educational/bat.jpg" alt="bat" className="factsImg"/>
                <div className="factsText">
                    
                    <p>Bats navigate and hunt using echolocation. They emit high-frequency sounds that bounce off objects, allowing them to create a detailed map of their surroundings in complete darkness. Some species can detect objects as fine as a human hair.</p>
                </div>
            </div>

            <h2>11. The Platypus: Nature's Oddity</h2>
            <div className="factsDiv">
                <img src="educational/platypus.jpg" alt="platypus" className="factsImg"/>
                <div className="factsText">
                    
                    <p>The platypus is one of the most unusual mammals. It lays eggs, has a duck-bill, webbed feet, and a beaver-like tail. Males also possess venomous spurs on their hind legs, making them one of the few venomous mammals.</p>
                </div>
            </div>

            <h2>12. The Tongue of a Chameleon</h2>
            <div className="factsDiv">
                <img src="educational/chameleon.jpg" alt="chameleon" className="factsImg"/>
                <div className="factsText">
                    
                    <p>A chameleon’s tongue can be twice the length of its body and is capable of shooting out at great speed to catch prey. Their tongues can accelerate from 0 to 60 miles per hour in a hundredth of a second.</p>
                </div>
            </div>

            <h2>13. Flamingos' Color</h2>
            <div className="factsDiv">
                <img src="educational/flamingo.jpg" alt="flamingo" className="factsImg"/>
                <div className="factsText">
                    
                    <p>Flamingos are born with gray feathers, but their diet of brine shrimp and blue-green algae, which are high in carotenoids, turns their feathers pink. The carotenoids are broken down into pigments, which are then deposited in the feathers.</p>
                </div>
            </div>

            <h2>14. The Pistol Shrimp’s Superpower</h2>
            <div className="factsDiv">
                <img src="educational/pistolshrimp.jpg" alt="pistol shrimp" className="factsImg"/>
                <div className="factsText">
                    
                    <p>The pistol shrimp possesses a specialized claw that snaps shut at incredible speeds, creating a cavitation bubble that reaches temperatures nearly as hot as the sun’s surface. This snap can stun or kill prey and is one of the loudest sounds in the ocean.</p>
                </div>
            </div>

            <h2>15. The Cheetah’s Speed</h2>
            <div className="factsDiv">
                <img src="educational/cheetah.jpg" alt="cheetah" className="factsImg"/>
                <div className="factsText">
                    
                    <p>Cheetahs are the fastest land animals, capable of reaching speeds up to 60 to 70 miles per hour in short bursts covering distances up to 500 meters. They can accelerate from 0 to 60 miles per hour in just a few seconds.</p>
                </div>
            </div>
   
            <p>We hope these facts have sparked your curiosity and deepened your appreciation for the animal kingdom's wonders. Keep exploring and discovering more about the incredible creatures that inhabit our planet!</p>



      <Footer />
        </div>
        
        </div>

    );
}

export default AnimalFacts;