// src/axiosConfig.js
/*
import axios from 'axios';

axios.defaults.baseURL = 'http://localhost:5000';
axios.defaults.withCredentials = true; // This ensures cookies are sent with every request

export default axios;*/
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

//console.log('API URL:', process.env.REACT_APP_API_URL);  // Add this line to verify

axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true; // This ensures cookies are sent with every request

export default axios;
