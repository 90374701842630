import React, { useState, useRef, useEffect } from 'react';
import Board from '../components/Board';
import { getContinents, getHabitatTypes, getHabitats, getDiet, getFoods, getGroupBehavior, getLifestyles, getColors, getClass, getOrder, getFamily, getDomesticatedStatus, getRandomAnimal, checkGuess, fetchWikipediaSummary, fetchWikipediaSummary2, fetchConservationStatus} from '../services/api';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/game/Grid.css';
import '../styles/game/Bottom.css';
import '../styles/game/Center.css';
import '../styles/game/Board.css';
import '../styles/game/Top.css';
import BottomRow from '../components/BottomRow';
import AnimalPopup from '../components/AnimalPopup'; // Import Popup component
import GuessPopup from '../components/GuessPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDollarToSlot, faCircleQuestion, faGear, faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HowToPlay from '../components/HowToPlay';
import GiveUpConfirmation from '../components/GiveUpConfirmation';
import AllGuessedPopup from '../components/AllGuessedPopup'; 
import LeafConfetti from '../particles/LeafConfetti';


function Game({difficulty}) {
    const [showLeaves, setShowLeaves] = useState(false);

    const location = useLocation(); // Use useLocation to get the current URL
    const queryParams = new URLSearchParams(location.search);
    //const difficulty = queryParams.get('difficulty');
    const navigate = useNavigate();

    const inputRef = useRef(null); // Create a ref for the input

    const [continents, setContinents] = useState([]);
    const [correctContinents, setCorrectContinents] = useState([]);
    const [sessionAnimalContinents, setSessionAnimalContinents] = useState([]);
    const [guessedContinents, setGuessedContinents] = useState([]);
    const [incorrectContinents, setIncorrectContinents] = useState([]);

    const [habitats, setHabitats] = useState([]);
    const [correctHabitats, setCorrectHabitats] = useState([]);
    const [sessionAnimalHabitats, setSessionAnimalHabitats] = useState([]);
    const [guessedHabitats, setGuessedHabitats] = useState([]);
    const [incorrectHabitats, setIncorrectHabitats] = useState([]);

    const [habitatTypes, setHabitatTypes] = useState([]);
    const [guessedHabitatType, setGuessedHabitatType] = useState([]);
    const [correctHabitatType, setCorrectHabitatType] = useState([]);
    const [sessionAnimalHabitatTypes, setSessionAnimalHabitatTypes] = useState([]);
    const [incorrectHabitatTypes, setIncorrectHabitatTypes] = useState([]);

    const [diet, setDiet] = useState([]);
    const [foods, setFoods] = useState([]);
    const [groupBehavior, setGroupBehavior] = useState([]);
    const [lifestyles, setLifestyles] = useState([]);
    const [colors, setColors] = useState([]);

    const [domesticated, setDomesticated] = useState([]);
    const [correctDomesticated, setCorrectDomesticated] = useState(null);
    const [domesticatedStyle, setDomesticatedStyle] = useState({});

    const [classData, setClassData] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [familyData, setFamilyData] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(null);

    const [taxonomySummary, setTaxonomySummary] = useState('');
    const [taxonomyImageUrl, setTaxonomyImageUrl] = useState('');
    const [taxonomyHeading, setTaxonomyHeading] = useState('Vertebrates');

    const [correctClass, setCorrectClass] = useState(null);
    const [correctOrder, setCorrectOrder] = useState(null);
    const [correctFamily, setCorrectFamily] = useState(null);

    const [animalClass, setAnimalClass] = useState(null);
    const [animalOrder, setAnimalOrder] = useState(null);
    const [animalFamily, setAnimalFamily] = useState(null);

    const [animalSci, setAnimalSci] = useState(null);

    const [correctDiet, setCorrectDiet] = useState(null);

    const [correctFavoriteFood, setCorrectFavoriteFood] = useState([]); 
    const [sessionAnimalFood, setSessionAnimalFood] = useState([]); 


    const [correctGroupBehavior, setCorrectGroupBehavior] = useState([]);
    const [correctLifestyle, setCorrectLifestyle] = useState([]); 

    const [animalNames, setAnimalNames] = useState([]);
    const [userGuess, setUserGuess] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [animalImageUrl, setAnimalImageUrl] = useState('');
    const [animalSummary, setAnimalSummary] = useState('');
    const [tempInputValue, setTempInputValue] = useState('');

    const [correctColors, setCorrectColors] = useState([]);
    const [sessionAnimalColors, setSessionAnimalColors] = useState([]);
    const [guessedColors, setGuessedColors] = useState([]);
    const [incorrectColors, setIncorrectColors] = useState([]);
    const [colorCount, setColorCount] = useState(0);
    const [hasMostColors, setHasMostColors] = useState(false);

    const [sessionAnimalGroupBehavior, setSessionAnimalGroupBehavior] = useState([]);
    const [sessionAnimalLifestyles, setSessionAnimalLifestyles] = useState([]);

    
    const [guessMessage, setGuessMessage] = useState('');
    const [guessType, setGuessType] = useState('');
    

    const [weightMessage, setWeightMessage] = useState('');
    const [weightUnit, setWeightUnit] = useState('kg');
    const [popupKey, setPopupKey] = useState(0);

    const [guessCount, setGuessCount] = useState(0);

    const [timer, setTimer] = useState(0);
    const [timerOn, setTimerOn] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [showGiveUpConfirmation, setShowGiveUpConfirmation] = useState(false);
    const [gameEndReason, setGameEndReason] = useState('');
    const [wrongGuesses, setWrongGuesses] = useState([]);

    const [showAllGuessedPopup, setShowAllGuessedPopup] = useState(false);
    const [availableDifficulties, setAvailableDifficulties] = useState(['easy', 'medium', 'hard']);

    const [animalConservationStatus, setAnimalConservationStatus] = useState('');
    
        const fetchData = async () => {
            try {
                //console.log("Fetching data...");
                const [
                    habitatTypesData,
                    habitatsData,
                    dietData,
                    foodsData,
                    groupBehaviorData,
                    lifestylesData,
                    colorsData,
                    domesticatedData,
                    classData,
                    orderData,
                    familyData,
                    continentsData,
                    randomAnimal, //= await getRandomAnimal(difficulty),
                    wikipediaSummary,
                ] = await Promise.all([
                    getHabitatTypes(),
                    getHabitats(),
                    getDiet(),
                    getFoods(),
                    getGroupBehavior(),
                    getLifestyles(),
                    getColors(),
                    getDomesticatedStatus(),
                    getClass(),
                    getOrder(),
                    getFamily(),
                    getContinents(),
                    getRandomAnimal(difficulty),
                    fetchWikipediaSummary('Taxonomic Rank'),
                ]);

                const conservationStatus = await fetchConservationStatus(randomAnimal.scientific);
                //console.log(conservationStatus);

                //console.log('randomAnimal:', randomAnimal);
                if (randomAnimal.action === "all_guessed") {
                    setShowAllGuessedPopup(true);
                    setIsLoading(false);
                    return;
                }


                setHabitatTypes(habitatTypesData);
                setHabitats(habitatsData);
                setDiet(dietData);
                setFoods(foodsData);
                setGroupBehavior(groupBehaviorData);
                setLifestyles(lifestylesData);
                setColors(colorsData);
                setDomesticated(domesticatedData);
                setClassData(classData);
                setOrderData(orderData);
                setFamilyData(familyData);
                setTaxonomySummary(wikipediaSummary.summary);
                setTaxonomyImageUrl(wikipediaSummary.imageUrl);
                setAnimalConservationStatus(conservationStatus);

                setCorrectClass(null);
                setCorrectOrder(null);
                setCorrectFamily(null);
                setCorrectDiet(null);
                setCorrectFavoriteFood([]);
                setCorrectGroupBehavior([]);
                setCorrectLifestyle([]);
                setTaxonomyHeading('Unknown');


                

                const filteredContinents = continentsData.filter(continent => continent !== 'Global');
                setContinents(filteredContinents);

                const filteredColors = colorsData.filter(colors => colors !== 'Most Colors');
                setColors(filteredColors);

                if (randomAnimal) {
                    //console.log('Random animal:', randomAnimal.name);
                    //setSessionAnimalHabitatTypes(randomAnimal.habitat_type || []);
                   // console.log("Habitat Types set for session from random animal:", randomAnimal.habitat_type);

                   setAnimalClass(randomAnimal.class);
                   setAnimalOrder(randomAnimal.order);
                   setAnimalFamily(randomAnimal.family);
                   setAnimalSci(randomAnimal.scientific);

                   setSessionAnimalFood(randomAnimal.food)

                    setAnimalNames(randomAnimal.name);
                    const animalSummary = await fetchWikipediaSummary2(randomAnimal.name[0]);
                    setAnimalImageUrl(animalSummary.imageUrl);
                    setAnimalSummary(animalSummary.summary);

                    
                    

                    if (randomAnimal.continents.includes('Global')) {
                        setSessionAnimalContinents(['Global']);
                        setCorrectContinents(['global']);
                        setGuessedContinents(['Global']);
                        //console.log('Animal has global continent');
                    } else if (randomAnimal.continents) {
                        setSessionAnimalContinents(randomAnimal.continents);
                        setCorrectContinents(new Array(randomAnimal.continents.length).fill(false));
                        setGuessedContinents(new Array(randomAnimal.continents.length).fill("Unknown"));
                        
                    }
                    setIncorrectContinents([]);

                    if (randomAnimal.habitats) {
                        setSessionAnimalHabitats(randomAnimal.habitats);
                        setCorrectHabitats(new Array(randomAnimal.habitats.length).fill(false));
                        setGuessedHabitats(new Array(randomAnimal.habitats.length).fill("Unknown"));
                    }
                    setIncorrectHabitats([]);

                    if (randomAnimal.habitat_type) {
                        setSessionAnimalHabitatTypes(randomAnimal.habitat_type || []);
                        //setCorrectHabitatType(new Array(randomAnimal.habitatTypes.length).fill(false));
                        //setGuessedHabitatType(new Array(randomAnimal.habitatTypes.length).fill("Unknown"));
                        //console.log("Habitat Types set for session from random animal:", randomAnimal.habitat_type);
                    }
                    setIncorrectHabitatTypes([]);

                    if (randomAnimal.colors) {
                        setSessionAnimalColors(randomAnimal.colors);
                        setColorCount(randomAnimal.colors.length);
                        setCorrectColors(new Array(randomAnimal.colors.length).fill(false));
                        setGuessedColors(new Array(randomAnimal.colors.length).fill(null));
                        if (randomAnimal.colors.includes('Most Colors')) {
                            setHasMostColors(true);
                        }
                    } else {
                        setColorCount(0);
                        setCorrectColors([]);
                        setGuessedColors([]);
                    }
                    setIncorrectColors([]);

                    if (randomAnimal.group_behavior) {
                        setSessionAnimalGroupBehavior(randomAnimal.group_behavior);
                        //console.log("session group behavior:", sessionAnimalGroupBehavior)
                    } else {
                        setSessionAnimalGroupBehavior([]);
                    }
    
                    if (randomAnimal.lifestyles) {
                        setSessionAnimalLifestyles(randomAnimal.lifestyles);
                    } else {
                        setSessionAnimalLifestyles([]);
                    }

                    if (randomAnimal.domesticated !== undefined) {
                        setCorrectDomesticated(randomAnimal.domesticated);
                    }
                    
                    const colorEvent = new Event('fetchColorCount');
                    window.dispatchEvent(colorEvent);
                    
                    const event = new Event('fetchContinentCount');
                    window.dispatchEvent(event);
                    const habitatEvent = new Event('fetchHabitatCount');
                    window.dispatchEvent(habitatEvent);

                    //setCorrectHabitatType(randomAnimal.habitatType); // Assuming habitatType is a property of randomAnimal
                    const habitatTEvent = new Event('fetchHabitatTCount');
                    window.dispatchEvent(habitatTEvent)

                    // Add debug logging to check the state values
                    //console.log('Continents:', filteredContinents);
                    //console.log('Habitat Types:', habitatTypesData);
                    //console.log('Habitats:', habitatsData);

                    //console.log("Random Animal", randomAnimal);

                    const groupBehaviorEvent = new Event('fetchGroupBehaviorCount');
                    window.dispatchEvent(groupBehaviorEvent);
                    const lifestyleEvent = new Event('fetchLifestyleCount');
                    window.dispatchEvent(lifestyleEvent);


                    setTimerOn(true); 
                    setIsLoading(false);

                    
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        useEffect(() => {
            let interval = null;
            if (timerOn) {
                interval = setInterval(() => {
                    setTimer(prevTimer => prevTimer + 1);
                }, 1000);  // Increment the timer every second
            } else if (!timerOn) {
                clearInterval(interval);  // Clear interval when timer is not running
            }
            return () => clearInterval(interval);  // Cleanup interval on component unmount
        }, [timerOn]);


    useEffect(() => {
        //console.log('User Agent:', navigator.userAgent);
            if (navigator.userAgent !== 'ReactSnap') {
        fetchData();
        }
        setTimer(0);
        setGuessCount(0);
        
        
    }, [difficulty]);


    const updateTaxonomyInfo = async (type, value) => {
        if (type === 'class' || type === 'order' || type === 'family') {
            const { summary, imageUrl } = await fetchWikipediaSummary(value);
            setTaxonomySummary(summary);
            setTaxonomyImageUrl(imageUrl);
            setTaxonomyHeading(value); // Update the heading
        }
    };

    const handleGuess = async (type, value) => {
        //console.log(`Handling guess for type: ${type}, value: ${value}`);
        const stateMap = {
            continent: setContinents,
            habitatType: setHabitatTypes,
            habitat: setHabitats,
            class: setClassData,
            order: setOrderData,
            family: setFamilyData,
            diet: setDiet,
            food: setFoods,
            groupBehavior: setGroupBehavior,
            lifestyle: setLifestyles,
            domesticated: setDomesticated,
            color: setColors,
        };
        if (type === 'diet' || type === 'groupBehavior' || type === 'lifestyle' || type === 'color' || type === 'domesticated') {
            await handleBehaviorGuess(type, value);
            setGuessCount(prevCount => prevCount + 1);
        } else if (type === 'habitatType') {
            try {
                const result = await checkGuess(type, value);
                setGuessMessage(result === "Correct" ? "Correct!" : "Incorrect!");
                setGuessType(result === "Correct" ? "correct" : "incorrect");
                setPopupKey(prevKey => prevKey + 1);
                setGuessCount(prevCount => prevCount + 1);
        
                if (result === "Correct") {
                    setCorrectHabitatType(prevCorrect => {
                        const updatedCorrect = [...prevCorrect, value];
                        // Check if all correct options are guessed and then mark remaining
                        checkAndMarkRemainingIncorrect(updatedCorrect);
                        return updatedCorrect;
                    });
                } else {
                    // Always add to incorrect unless it's already marked correct
                    setIncorrectHabitatTypes(prevIncorrect => [...new Set([...prevIncorrect, value])]);
                }
        
                // Optionally, remove the guessed type from the list of options if you do not allow re-guessing
                setHabitatTypes(prevTypes => prevTypes.filter(item => item.name !== value));
                
            } catch (error) {
                console.error('Error checking guess:', error.response ? error.response.data : error.message);
            }
        
        
        
        
        } else {
            if (stateMap[type]) {
                stateMap[type](prev => prev.filter(item => item !== value));
            }

            if (type === 'continent') {
                try {
                    const result = await checkGuess(type, value);
                    setGuessMessage(result === "Correct" ? "Correct!" : "Incorrect!");
                    setGuessType(result === "Correct" ? "correct" : "incorrect");
                    setPopupKey(prevKey => prevKey + 1);
                    setGuessCount(prevCount => prevCount + 1);
                    if (result === "Correct") {
                        if (value === 'Global') {
                            //console.log('Global guess correctly identified');
                            return;
                        }
                        const formattedValue = value.toLowerCase().replace(/ /g, '-');
                        const index = sessionAnimalContinents.indexOf(value);
                        if (index !== -1) {
                            setCorrectContinents(prev => prev.map((item, idx) => idx === index ? formattedValue : item));
                            setGuessedContinents(prev => prev.map((item, idx) => idx === index ? value : item));
                        }
                    } else {
                        setIncorrectContinents(prev => [...prev, value]);
                    }
                } catch (error) {
                    console.error('Error checking guess:', error.response ? error.response.data : error.message);
                }


            } else if (type === 'habitat') {
                const result = await checkGuess(type, value);
                setGuessMessage(result === "Correct" ? "Correct!" : "Incorrect!");
                setGuessType(result === "Correct" ? "correct" : "incorrect");
                setPopupKey(prevKey => prevKey + 1);
                setGuessCount(prevCount => prevCount + 1);
                const index = sessionAnimalHabitats.indexOf(value);
                const formattedValue = value.toLowerCase().replace(/ /g, '-');
                if (result === "Correct") {
                    if (index !== -1) {
                        // Update correct and guessed habitats
                        setCorrectHabitats(prev => prev.map((item, idx) => idx === index ? formattedValue : item));
                        setGuessedHabitats(prev => prev.map((item, idx) => idx === index ? value : item));
                    }
                } else {
                    setIncorrectHabitats(prev => [...prev, value]);
                }

            } else if (type === 'weight') {
                const result = await checkGuess(type, value);
                setGuessMessage(result);
                setGuessType("weight");
                setPopupKey(prevKey => prevKey + 1);
                setWeightMessage(result);
                setGuessCount(prevCount => prevCount + 1);
                return result;

            } else {
                try {
                    const result = await checkGuess(type, value);
                    setGuessMessage(result === "Correct" ? "Correct!" : "Incorrect!");
                    setGuessType(result === "Correct" ? "correct" : "incorrect");
                    setPopupKey(prevKey => prevKey + 1);
                    setGuessCount(prevCount => prevCount + 1);
            
                    if ((type === 'class' || type === 'order' || type === 'family') && result === "Correct") {
                        await updateTaxonomyInfo(type, value);
                    }
            
                    if (type === 'class') {
                        setClassData(prev => prev.filter(item => item.name !== value));
            
                        const result = await checkGuess(type, value);
                        if (result === "Correct") {
                            setCorrectClass(value);
                            const classItem = classData.find(c => c.name === value);
                            if (!classItem) return;
            
                            const filteredOrders = classItem.orders
                                .map(orderName => orderData.find(order => order.name === orderName))
                                .filter(Boolean); // Filter out any undefined values
                            setOrderData(filteredOrders);
            
                            const allFamilies = filteredOrders.flatMap(order => order ? order.families : []).filter(Boolean);
                            setFamilyData(allFamilies);
                        }
                    } else if (type === 'order') {
                        setOrderData(prev => prev.filter(item => item.name !== value));
            
                        const result = await checkGuess(type, value);
                        if (result === "Correct") {
                            setCorrectOrder(value);
                            const orderItem = orderData.find(o => o.name === value);
                            if (!orderItem) return;
            
                            setFamilyData(orderItem.families);
                            setOrderData(prev => prev.filter(item => item.name !== value));
                        }
                    } else if (type === 'family') {
                        const result = await checkGuess(type, value);
                        if (result === "Correct") {
                            setCorrectFamily(value);
                        }
                    }
                    
                } catch (error) {
                    console.error('Error checking guess:', error.response ? error.response.data : error.message);
                }
            }
        }
    };

    const handleBehaviorGuess = async (type, value) => {
        try {
            let mappedValue = value;
            if (type === 'domesticated') {
                mappedValue = value === 'Yes' ? '1' : '0';
            }
            const result = await checkGuess(type, mappedValue);
            setGuessMessage(result === "Correct" ? "Correct!" : "Incorrect!");
            setGuessType(result === "Correct" ? "correct" : "incorrect");
            setPopupKey(prevKey => prevKey + 1);
            if (type === 'diet') {
                setDiet(prev => prev.filter(item => item.name !== value));
                if (result === "Correct") {
                    setCorrectDiet(value);
                    const foodsRelatedToDiet = sessionAnimalFood;
                    setCorrectFavoriteFood(foodsRelatedToDiet);
                  
                }
            } else if (type === 'groupBehavior') {
                setGroupBehavior(prev => prev.filter(item => item !== value));  // Correct filtering for groupBehavior
                if (result === "Correct") {
                    setCorrectGroupBehavior(prev => [...prev, value]);
                }
            } else if (type === 'lifestyle') {
                setLifestyles(prev => prev.filter(item => item !== value));  // Correct filtering for lifestyle
                if (result === "Correct") {
                    setCorrectLifestyle(prev => [...prev, value]);
                }
            
            } else if (type === 'color') {
                setColors(prev => prev.filter(item => item !== value));
                if (result === "Correct") {
                    const index = sessionAnimalColors.indexOf(value);
                    //console.log(value, index, "was correct!");
                    if (index !== -1) {
                        const newGuessedColors = [...guessedColors];
                        newGuessedColors[index] = value;
                        setGuessedColors(newGuessedColors);
                        //console.log("Updated guessedColors:", newGuessedColors);
    
                       /* const newCorrectColors = [...correctColors];
                        newCorrectColors[index] = value;
                        setCorrectColors(newCorrectColors);
                        console.log("Updated correctColors:", newCorrectColors);*/
                    }
                } else {
                    setIncorrectColors(prev => [...prev, value]);
                    //console.log(value, "was incorrect!");
                }
            } else if (type === 'domesticated') {
                setDomesticated(prev => prev.filter(item => item !== value));
                const actualValue = correctDomesticated ? 'Yes' : 'No';
                const style = correctDomesticated ? { color: 'green', textDecoration: 'underline' } : { color: 'red', textDecoration: 'line-through' };
                setDomesticatedStyle(style);
                setCorrectDomesticated(actualValue);
            }
        } catch (error) {
            console.error('Error checking guess:', error.response ? error.response.data : error.message);
        }
    };

    const handleNameGuess = async (animalName, isGiveUp = false) => {
        const guessLower = isGiveUp ? animalName.toLowerCase().trim() : userGuess.toLowerCase().trim();
        try {
            const response = await checkGuess('name', guessLower);
    
            if (response === "Correct" || isGiveUp) {
                inputRef.current.blur();
                setShowPopup(true);
                setShowLeaves(true);
                setTimeout(() => setShowLeaves(false), 5000);
                setGameEndReason(isGiveUp ? 'gaveUp' : 'correct');
                setTimerOn(false);
                setGuessCount(prevCount => prevCount + 1);
                if (isGiveUp) {
                    // Additional logic specific to giving up
                    setTimer("Gave up");
                    setGuessCount("Gave up");
                    setShowLeaves(true);
                }
            } else {
                //setUserGuess(''); 
                setGuessMessage("Incorrect!");
                setGuessType("incorrect");
                setPopupKey(prevKey => prevKey + 1);
                setGuessCount(prevCount => prevCount + 1);
                setWrongGuesses(prev => [...prev, guessLower]);
                //setTempInputValue('');
                
                setTempInputValue('');
                setUserGuess('');
                
                
            }
        } catch (error) {
            console.error('Error during name guess:', error);
        } finally {
            setTempInputValue('');
            setUserGuess('');
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleNameGuess();
        }
    };

    const handleNewGame = () => {
        setIsLoading(true);
        setTimer(0);
        setTimerOn(true);
        setGuessCount(0);
        setShowPopup(false);
        setUserGuess('');
        setAnimalNames([]);
        setCorrectContinents([]);
        setGuessedContinents([]);
        setIncorrectContinents([]);
        setCorrectHabitats([]);
        setGuessedHabitats([]);
        setIncorrectHabitats([]);
        setCorrectHabitatType([]);
        setGuessedHabitatType([]);
        setIncorrectHabitatTypes([]);
        setCorrectClass(null);
        setCorrectOrder(null);
        setCorrectFamily(null);
        setCorrectDiet(null);
        setCorrectFavoriteFood([]);
        setCorrectGroupBehavior([]);
        setCorrectLifestyle([]);
        setCorrectDomesticated();
        setDomesticatedStyle({});
        setColorCount(0); // Reset the color count
        setCorrectColors([]);
        setGuessedColors([]);
        setIncorrectColors([]);
        setHasMostColors(false); // Reset the hasMostColors state
        setWeightMessage();
        setWrongGuesses([]);
        fetchData();
        setShowLeaves(false);
    };

    const checkAndMarkRemainingIncorrect = (updatedCorrect) => {
        // Check if all correct types are guessed
        if (sessionAnimalHabitatTypes.every(ht => updatedCorrect.includes(ht))) {
            const allHabitatNames = habitatTypes.map(ht => ht.name);
            const newIncorrectTypes = allHabitatNames.filter(ht => !updatedCorrect.includes(ht));
            setIncorrectHabitatTypes(prevIncorrect => {
                // Merge new incorrect types with existing ones, avoiding duplicates
                return [...new Set([...prevIncorrect, ...newIncorrectTypes])];
            });
        }
    };

    const handleGiveUpClick = () => {
        setShowGiveUpConfirmation(true);
    };

    const handleRestart = () => {
        setShowAllGuessedPopup(false);
        handleNewGame();
    };

    const handleChangeDifficulty = (newDifficulty) => {
        setShowAllGuessedPopup(false);
        navigate(`/Play?difficulty=${newDifficulty}`);
    };

    return (
        <div className="game-container">
            <div className="gameContColor"/>
            <Helmet>
                <title>WildGuesser - Play {difficulty}</title>
                <meta name="description" content="Play Wildguesser, the animal guessing game. Test your animal knowledge and become a wildlife expert!"></meta>
            
                {/*<!-- Open Graph / Facebook -->*/}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://wildguesser.com/play"/>
                <meta property="og:title" content="WildGuesser - Play"/>
                <meta property="og:description" content="Play Wildguesser, the animal guessing game. Test your animal knowledge and become a wildlife expert!"/>
                <meta property="og:image" content="logoetc/logofull.png"/>

                {/*<!-- Twitter -->*/}
                <meta property="twitter:card" content="logoetc/logofull.png"/>
                <meta property="twitter:url" content="https://wildguesser.com/play"/>
                <meta property="twitter:title" content="WildGuesser - Play"/>
                <meta property="twitter:description" content="Play Wildguesser, the animal guessing game. Test your animal knowledge and become a wildlife expert!"/>
                <meta property="twitter:image" content="logoetc/logofull.png"/>
            
            </Helmet>
            {isLoading ? (
                <div>
                <img className="loading" src="/gif/loading2.gif" alt="Loading"></img>
                <div className="adTing"></div>
                </div>
            ): (
            <>
            
            <div className="top-row">
                <div className="navIconCont">{/*<button className="navBtn">Donate<FontAwesomeIcon className="circleQ2" icon={faCircleDollarToSlot} /></button>*/}</div>
                <Link to={"/"}>
                <img className="logo" src="/logoetc/logofullsvg5.svg" alt="Home" />
                </Link>
                <div className="navIconCont"><HowToPlay /></div>
            </div>
            <div className="center-row">
            <Board
                correctContinents={correctContinents}
                guessedContinents={guessedContinents}
                incorrectContinents={incorrectContinents}

                guessedHabitatType={guessedHabitatType}
                correctHabitatType={correctHabitatType}
                incorrectHabitatTypes={incorrectHabitatTypes}
                sessionAnimalHabitatTypes={sessionAnimalHabitatTypes}

                correctHabitats={correctHabitats}
                guessedHabitats={guessedHabitats}
                incorrectHabitats={incorrectHabitats}
                taxonomySummary={taxonomySummary}
                taxonomyImageUrl={taxonomyImageUrl}
                taxonomyHeading={taxonomyHeading}
                correctDiet={correctDiet}
                correctFavoriteFood={correctFavoriteFood}
                correctGroupBehavior={correctGroupBehavior}
                correctLifestyle={correctLifestyle}
                correctColors={correctColors}
                guessedColors={guessedColors}
                incorrectColors={incorrectColors}
                colorCount={colorCount}
                hasMostColors={hasMostColors} 
                correctDomesticated={correctDomesticated} 
                domesticatedStyle={domesticatedStyle}
                weightMessage={weightMessage}
                weightUnit={weightUnit}
            />
            </div>
            <BottomRow
                continents={continents}
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                handleGuess={handleGuess}
                guessedContinents={guessedContinents}
                sessionAnimalContinents={sessionAnimalContinents}
                habitatTypes={habitatTypes}
                correctHabitatType={correctHabitatType}
                habitats={habitats}
                guessedHabitats={guessedHabitats}
                sessionAnimalHabitats={sessionAnimalHabitats}
                classData={classData}
                correctClass={correctClass}
                orderData={orderData}
                correctOrder={correctOrder}
                familyData={familyData}
                correctFamily={correctFamily}
                diet={diet}
                groupBehavior={groupBehavior}
                lifestyles={lifestyles}
                domesticated={domesticated}
                colors={colors}
                correctDiet={correctDiet}
                userGuess={userGuess}
                setUserGuess={setUserGuess}
                handleNameGuess={handleNameGuess}
                handleKeyPress={handleKeyPress}
                sessionAnimalColors={sessionAnimalColors}
                guessedColors={guessedColors}
                guessedHabitatType={guessedHabitatType}
                sessionAnimalHabitatTypes={sessionAnimalHabitatTypes}
                sessionGroupBehavior={sessionAnimalGroupBehavior}
                correctGroupBehavior={correctGroupBehavior}
                correctLifestyles={correctLifestyle}
                sessionLifestyles={sessionAnimalLifestyles}
                hasMostColors={hasMostColors} 
                correctDomesticated={correctDomesticated} 
                setGuessMessage={setGuessMessage}
                guessCount={guessCount}
                timer={timer}
                handleGiveUpClick={handleGiveUpClick}
                inputRef = {inputRef}
                wrongGuesses = {wrongGuesses}
                setWeightUnit={setWeightUnit}
                tempInputValue={tempInputValue}
                setTempInputValue={setTempInputValue}
            />
            {showPopup && (
                <AnimalPopup
                message={gameEndReason === 'correct' ? `Correct guess! The animal was:` : `Better luck next time. The animal was:`}
                    imageUrl={animalImageUrl} // Pass the image URL to the popup
                    animalSummary={animalSummary}
                    onNewGame={handleNewGame}
                    guessCount={guessCount}
                    timer={timer}
                    animalNames = {animalNames}
                    animalClass={animalClass}
                    animalOrder={animalOrder}
                    animalFamily={animalFamily}
                    animalSci={animalSci}
                    animalConservationStatus ={animalConservationStatus}
                />
            )}
            {guessMessage && (
                <GuessPopup
                    message={guessMessage}
                    type={guessType}
                    onClose={() => setGuessMessage('')}
                    key={popupKey}
                />
            )}
            {showGiveUpConfirmation && (
                <GiveUpConfirmation
                    onClose={() => setShowGiveUpConfirmation(false)}
                    onConfirm={() => {
                        setShowGiveUpConfirmation(false);
                        setShowPopup(true);
                        setGameEndReason('gaveUp');
                        setTimer("Gave up");
                        setGuessCount("Gave up");
                        handleNameGuess(animalNames[0], true);
                    }}
                />
            )}
            {showAllGuessedPopup && (
                <AllGuessedPopup
                    difficulty={difficulty}
                    onClose={() => setShowAllGuessedPopup(false)}
                    onRestart={handleRestart}
                    onChangeDifficulty={handleChangeDifficulty}
                />
            )}
            {/*showLeaves && <LeafConfetti numLeaves={100} />*/}
            </>
            )}
        </div>
    );
}

export default Game;