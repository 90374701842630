import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/NavBar.jsx'
import Footer from "../components/Footer.jsx";
import { Helmet } from 'react-helmet';

const Donations = () => {
  return (
    <div>
        <Helmet>
                <title>WildGuesser - Donations</title>
                <meta name="description" content="How donations on Wildguesser work. What we use the money for, and how much of it goes towards conservation."/>
            
                {/*<!-- Open Graph / Facebook -->*/}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://wildguesser.com/"/>
                <meta property="og:title" content="WildGuesser - Donations"/>
                <meta property="og:description" content="How donations on Wildguesser work. What we use the money for, and how much of it goes towards conservation."/>
                <meta property="og:image" content="logoetc/logofull.png"/>

                {/*<!-- Twitter -->*/}
                <meta property="twitter:card" content="logoetc/logofull.png"/>
                <meta property="twitter:url" content="https://wildguesser.com/"/>
                <meta property="twitter:title" content="WildGuesser - Donations"/>
                <meta property="twitter:description" content="How donations on Wildguesser work. What we use the money for, and how much of it goes towards conservation."/>
                <meta property="twitter:image" content="logoetc/logofull.png"/>
            
            </Helmet>
        <NavBar/>
    <div className="ToSbody">
      <h1>Support Wildguesser and Help Protect Nature!</h1>

      <div className="donateBtnCont">
      <Link to={"https://donate.stripe.com/28oaHa5xS3e9f7O6oo"} style={{ textDecoration: 'none' }} target="_blank">
        <button className="donoBtn2">Donate Now</button>
        </Link>
        <i>This button will take you to our donation link on Stripe</i>
      </div>
      <p>
        At Wildguesser, we are passionate about animals and their conservation. Our animal guessing game is not only a fun way to learn about the diverse species on our planet, but it also serves a greater purpose. By donating to Wildguesser, you are directly contributing to the well-being of our planet and its inhabitants.
      </p>
      <h2>How Your Donation Helps</h2>
      <h3>50% to The Nature Conservancy</h3>
      <p>
        Half of your donation goes to The Nature Conservancy, a leading organization dedicated to conserving the lands and waters on 
        which all life depends. By supporting them, you are helping to ensure that the amazing animals featured on Wildguesser can thrive in their natural habitats.
      </p>
      <p><i>We are not partnered with The Nature Conservancy, but have chosen them as the organization to donate to.</i></p>
      <h3>50% to Wildguesser</h3>
      <p>
        The other half of your donation supports Wildguesser.com. This helps us cover hosting costs, maintain the website, and continue 
        improving the game experience for you and other animal enthusiasts. This enables us to continue educating people about animals.
      </p>
      <h2>Donation Details</h2>
      <ul>
        <li><strong>Minimum Donation:</strong> $15</li>
        <li><strong>Non-Tax Deductible:</strong> Please note that your donation is not tax-deductible.</li>
        <li>
          <strong>Donation Schedule:</strong> If the total donations received in a month are $50 or more, we will donate the funds to The Nature Conservancy at the end of that month. If the total is less than $50, the funds will be carried over to the next month until the total reaches $50 or more.
        </li>
      </ul>
      <p>
        By donating to Wildguesser, you are not only helping to keep our website running but also contributing to the crucial work of animal conservation. Together, we can make a difference!
      </p>
      <h2>Why The Nature Conservancy?</h2>
      <p>The Nature Conservancy is one of the most respected and effective environmental organizations in the world. With a mission to conserve the lands and waters on which all life depends, they have protected more than 125 million acres of land and thousands of miles of rivers globally. Their science-based approach ensures that every dollar donated makes a significant impact. By sending 50% of our received donations to The Nature Conservancy, we are supporting a proven leader in biodiversity preservation, helping to secure a future for the amazing animals we celebrate on Wildguesser. Your donation aids vital conservation projects, from safeguarding habitats to restoring ecosystems, ensuring that wildlife can thrive for generations to come.</p>
      <h2>Thank you for your support and generosity.</h2>
      
        
      
      <Footer/>
    </div>
    
    </div>
  );
}

export default Donations;
