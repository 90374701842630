// src/components/FullArticleModal.js
import React from 'react';
import PropTypes from 'prop-types';
import '../styles/game/FullArticleModal.css'; // Ensure you create appropriate styles
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FullArticleModal = ({ articleContent, articleHeading, onClose, thumbnail }) => (
    <div className="article-overlay" onClick={onClose}>
        <div className="article-container" onClick={(e) => e.stopPropagation()}>
            <div className="articleTop">
                <div className="articleEmpty"/>
                <div className="article-heading">{articleHeading}</div>
                <div className="close-button" onClick={onClose}><FontAwesomeIcon icon={faXmark} /></div>
            </div>

            <div className="article-content-wrapper">
                {/*thumbnail && <img src={thumbnail} alt="Article thumbnail" className="article-image" />*/}
                <div className="article-content" dangerouslySetInnerHTML={{ __html: articleContent }} />
            </div>
        </div>
    </div>
);

FullArticleModal.propTypes = {
    articleHeading: PropTypes.string.isRequired,
    articleContent: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};


export default FullArticleModal;
