import React, { useState, useEffect } from 'react';
import '../styles/other/Other.css'
import { Link } from 'react-router-dom';
import Footer from "../components/Footer.jsx";
import { Helmet } from 'react-helmet';
import NavBar from '../components/NavBar.jsx'

function Contact() {
    return(
        <div>
                        <Helmet>
                <title>Contact - Wildguesser</title>
                <meta name="description" content="Get in touch with the WildGuesser team for support, feedback, or inquiries." />

                {/*<!-- Open Graph / Facebook -->*/}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://wildguesser.com/contact"/>
                <meta property="og:title" content="Contact - Wildguesser"/>
                <meta property="og:description" content="Get in touch with the WildGuesser team for support, feedback, or inquiries."/>
                <meta property="og:image" content="logoetc/logofull.png"/>

                {/*<!-- Twitter -->*/}
                <meta property="twitter:card" content="logoetc/logofull.png"/>
                <meta property="twitter:url" content="https://wildguesser.com/contact"/>
                <meta property="twitter:title" content="Contact - Wildguesser"/>
                <meta property="twitter:description" content="Get in touch with the WildGuesser team for support, feedback, or inquiries."/>
                <meta property="twitter:image" content="logoetc/logofull.png"/>   

            </Helmet>
            <NavBar/>
        <div className="ToSbody">


             <h1>Contact Us</h1>
            <p>
                For all inquiries, please reach us at <a href="mailto:contact@wildguesser.com">contact@wildguesser.com</a>.
            </p>
            <p>Our dedicated team will respond to your messages as soon as possible.</p>
            <h2>Reasons to Contact Us:</h2>
            <ul>
                <li><strong>General Inquiries</strong>: If you have any questions or feedback about Wildguesser, please do not hesitate to contact us. We are here to help with any information you need about our platform, services, and mission.</li>
                <li><strong>Advertising Opportunities</strong>: Companies interested in advertising with us have a unique opportunity to reach a passionate audience that loves both games and animals. Partnering with Wildguesser allows you to connect with engaged users who are enthusiastic about wildlife and gaming.</li>
                <li><strong>Partnerships and Collaborations</strong>: We welcome organizations that are working to help and conserve endangered species. If your organization is interested in partnering with Wildguesser, we are eager to discuss potential collaborations. Together, we can make a significant impact on wildlife conservation. We are committed to giving 50% of our donations towards wildlife conservation, and partnering with a company dedicated to this cause would be incredibly beneficial.</li>
                <li><strong>Donations and Support</strong>: Learn how you can support our mission to protect endangered species and promote wildlife conservation. We provide information on different ways to donate and support our cause. Your contributions can make a real difference in our efforts to preserve biodiversity. Donations are currently unavailable, but we are working to make this option available as soon as possible.</li>
            </ul>
            <p>Thank you for supporting Wildguesser.</p>
      
            <h2>Feedback:</h2>
            <p>We value your feedback! Your comments and suggestions are crucial for us to improve and enhance your experience with Wildguesser. Whether it's about animal data accuracy, game mechanics, user experience, user interface, or any other aspect of the game, we want to hear from you.</p>
            <p>Please share your feedback on our subreddit: <a href="https://www.reddit.com/r/Wildguesser/" target="_blank">www.reddit.com/r/Wildguesser</a>. Engaging with our community helps us understand your needs better and implement improvements that benefit all users.</p>
            <p>Thank you for supporting Wildguesser. Together, we can create a more informed and engaged community dedicated to learning about and appreciating the diversity of animal life. Your involvement and support are the driving forces behind our mission to raise awareness and educate people about the fascinating world of animals.</p>

            <h2>Follow us on social media:</h2>
            <p><a href="https://www.instagram.com/wildguesser/" target="_blank">Instagram</a></p>
            <p><a href="https://www.facebook.com/profile.php?id=61560974100871" target="_blank">Facebook</a></p>

            <br/>
            <p>Thank you once again for your interest in Wildguesser. Your engagement and support help us move closer to our goal of raising awareness and educating people about the world's diverse animal life.</p>

            <Footer />

        </div>
        </div>
    );
}

export default Contact;