import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GenericCard from './GenericCard';
import { getCount, fetchWikipediaFullArticle } from '../services/api';
import '../styles/game/Board.css';
import ColorCard from './ColorCard';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WeightDropdown from './WeightDropdown';
import FullArticleModal from './FullArticleModal';


const Board = ({ correctContinents, guessedContinents, incorrectContinents, guessedHabitatType, correctHabitatType, incorrectHabitatTypes, correctHabitats, guessedHabitats, incorrectHabitats, 
    taxonomySummary, taxonomyImageUrl, taxonomyHeading, correctDiet, correctFavoriteFood, correctGroupBehavior, correctLifestyle, correctColors, guessedColors, incorrectColors , sessionAnimalHabitatTypes, weightMessage,
    hasMostColors, correctDomesticated, domesticatedStyle, weightUnit    }) => {
   /* console.log("Board received correctColors:", correctColors);
    console.log("Board received guessedColors:", guessedColors);
    console.log("Board received incorrectColors:", incorrectColors);
    console.log("Board received colorCount:", colorCount);*/
    const [continentCount, setContinentCount] = useState(0);
    const [habitatCount, setHabitatCount] = useState(0);
    const [groupBehaviorCount, setGroupBehaviorCount] = useState(0);
    const [lifestyleCount, setLifestyleCount] = useState(0);
    const [colorCount, setColorCount] = useState(0);
    const [habitatTCount, setHabitatTCount] = useState(0);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fullArticle, setFullArticle] = useState('');
    const [articleHeading, setArticleHeading] = useState(''); // Add this line
    const [thumbnail, setThumbnail] = useState(''); // Add this line

    //console.log('correctFavoriteFood', correctFavoriteFood);

    useEffect(() => {
        const handleFetchCount = async (type, setter) => {
            const count = await getCount(type);
            setter(count);
        };

        const handleFetchContinentCount = () => handleFetchCount('continent', setContinentCount);
        const handleFetchHabitatCount = () => handleFetchCount('habitat', setHabitatCount);
        const handleGroupBehaviorCount = ()=> handleFetchCount('groupBehavior', setGroupBehaviorCount);
        const handleLifestyleCount = ()=> handleFetchCount('lifestyle', setLifestyleCount);
        const handleColorCount = ()=> handleFetchCount('color', setColorCount);
        const handleHabitatTCount = ()=> handleFetchCount('habitat_type', setHabitatTCount);

        

        

        window.addEventListener('fetchContinentCount', handleFetchContinentCount);
        window.addEventListener('fetchHabitatCount', handleFetchHabitatCount);
        window.addEventListener('fetchGroupBehaviorCount', handleGroupBehaviorCount);
        window.addEventListener('fetchLifestyleCount', handleLifestyleCount);
        window.addEventListener('fetchColorCount', handleColorCount);
        window.addEventListener('fetchHabitatTCount', handleHabitatTCount);

        handleFetchContinentCount(); // Initial fetch
        handleFetchHabitatCount(); // Initial fetch
        handleGroupBehaviorCount();
        handleLifestyleCount();
        handleColorCount();
        handleHabitatTCount();

        return () => {
            window.removeEventListener('fetchContinentCount', handleFetchContinentCount);
            window.removeEventListener('fetchHabitatCount', handleFetchHabitatCount);
            window.removeEventListener('fetchGroupBehaviorCount', handleGroupBehaviorCount);
            window.removeEventListener('fetchLifestyleCount', handleLifestyleCount);
            window.removeEventListener('fetchColorCount', handleColorCount);
        };
    }, []);

    const habitatTypeStyle = (type) => {
        const isCorrect = correctHabitatType.includes(type);
        const isIncorrect = incorrectHabitatTypes.includes(type) && !correctHabitatType.includes(type); // Ensure not to mark as incorrect if it's correct
        if (isCorrect) {
            return { color: 'green', textDecoration: 'underline' };
        } else if (isIncorrect) {
            return { color: 'red', textDecoration: 'line-through' };
        }
        return { color: 'black', textDecoration: 'none' };
    };

    const renderUnknowns = (count, correctItems) => {
        const items = [];
        for (let i = 0; i < count; i++) {
            const itemValue = correctItems[i] || 'Hidden';
            const itemStyle = getStyle(itemValue);
            items.push(<span key={i} style={itemStyle}>{itemValue}</span>);
            if (i < count - 1) {
                items.push(<span key={`comma-${i}`}>, </span>); // Add commas
            }
        }
        return items;
    };

    const formatWeightMessage = (message) => {
        if (!message) return { text: 'Make a guess for weight.', style: {} };
    
        let formattedMessage = message;
        let messageStyle = {};
    
        // Extract the numeric values for weight from the message
        const weightValues = message.match(/\d+(\.\d+)?/g).map(weight => parseFloat(weight));
    
        if (weightUnit === 'lbs' && weightValues.length > 0) {
            const weightsInLbs = weightValues.map(weight => (weight * 2.20462).toFixed(2)); // Convert all found weights to pounds
    
            // Reconstruct the message based on the original message context
            if (message.includes('Incorrect, the animal weighs more than')) {
                formattedMessage = `Incorrect, the animal weighs more than ${weightsInLbs[0]} lbs.`;
            } else if (message.includes('Incorrect, the animal weighs less than')) {
                formattedMessage = `Incorrect, the animal weighs less than ${weightsInLbs[0]} lbs.`;
            } else if (message.includes('Correct, this animal weighs between')) {
                formattedMessage = `Correct, this animal weighs between ${weightsInLbs[0]} and ${weightsInLbs[1]} lbs.`;
            }
        }
    
        // Set text color based on correctness
        if (message.toLowerCase().includes('incorrect')) {
            messageStyle = { color: 'red' };
        } else if (message.toLowerCase().includes('correct')) {
            messageStyle = { color: 'green' };
        } else if (message.toLowerCase().includes('close')) {
            messageStyle = {color: 'orange'};
        }
    
        return { text: formattedMessage, style: messageStyle };
    };

    const visibleStyle = { color: 'green' };
    const hiddenStyle = { color: 'black', textDecoration: 'none' };

    const getStyle = (value) => value && value !== 'Hidden' ? visibleStyle : hiddenStyle;

    const handleExpandClick = async () => {
        const heading = taxonomyHeading === 'Unknown' ? 'Taxonomic rank' : taxonomyHeading;
        try {
            const { fullArticle: fullArticleContent, thumbnail: articleThumbnail } = await fetchWikipediaFullArticle(heading); // Fetch the full article content as HTML
            setFullArticle(fullArticleContent);
            setArticleHeading(heading);
            setThumbnail(articleThumbnail); // Set the thumbnail state
            setIsModalOpen(true); // Open the modal
        } catch (error) {
            console.error('Error fetching full article content:', error);
        }
    };

    return (
        <div className="boardNtexture">
        <div className="board">
            
        
            <GenericCard
                title="Continents"
                correctItems={correctContinents}
                guessedItems={guessedContinents}
                incorrectItems={incorrectContinents}
                itemCount={continentCount}
                itemType="continent"
                backgroundColor="#67666c"
            />
            <div className="sCard">
            
                <div className="sH">Habitat Types: {habitatTCount}</div>
                <div className="singleItem">
                    <b className="miniH">Find out the animal's habitat type</b>
                    <p style={habitatTypeStyle('Terrestrial')}><b style={habitatTypeStyle('Terrestrial')}>Terrestrial</b> = Land animals</p>
                    <p style={habitatTypeStyle('Arboreal')}><b style={habitatTypeStyle('Arboreal')}>Arboreal</b> = Lives in trees</p>
                    <p style={habitatTypeStyle('Semi-Aquatic')}><b style={habitatTypeStyle('Semi-Aquatic')}>Semi-Aquatic</b> = Amphibious</p>
                    <p style={habitatTypeStyle('Aquatic')}><b style={habitatTypeStyle('Aquatic')}>Aquatic</b> = Lives in water</p>
                </div>
            </div>
            <GenericCard
                title="Habitats"
                correctItems={correctHabitats}
                guessedItems={guessedHabitats}
                incorrectItems={incorrectHabitats}
                itemCount={habitatCount}
                itemType="habitat"
                backgroundColor="#635a4a"
            />
             <div className="taxonomyCard">
             
             <img src="/png/redpin2.png" className="pushPin3" alt="pushpin"></img>
                <div className="taxHeadingCont">
                    <p></p>
                    <h4>Taxonomy: {taxonomyHeading}</h4> 
                    <FontAwesomeIcon className="expand" icon={faExpand} onClick={handleExpandClick} />
                    </div>
                <div className="taxData">
                    {taxonomyImageUrl && <img src={taxonomyImageUrl} alt="Taxonomy" className="taxImg" draggable="false"/>}
                    <div className="taxSummary"><p>{taxonomySummary}</p></div>
                    <b>Click expand to read more.</b>
                </div>
            </div>

            <div className="behaviorCard">
            <img src="/png/redpin2.png" className="pushPin2" alt="pushpin"></img>
                <div className="behaviorItm">
                    <div className="behaveAnswr"><h5>Diet:</h5> <p style={getStyle(correctDiet)}>{correctDiet || 'Hidden'}</p></div>
                    <div className="behaveAnswr"><h5>Food:</h5> <p style={getStyle(correctFavoriteFood.join(' , '))}>{correctFavoriteFood.length > 0 ? correctFavoriteFood.join(' , ') : 'Hidden'}</p></div>
                </div>
                <div className="behaviorItm"><div className="behaveAnswr"></div></div>
                <div className="behaviorItm">
                    <div className="behaveAnswr"><h5>Group Behavior{groupBehaviorCount > 1 ? `(${groupBehaviorCount})` : ''}:</h5>
                       <p> {renderUnknowns(groupBehaviorCount, correctGroupBehavior)}</p>
                    </div>
                    <div className="behaveAnswr"><h5>Lifestyle{lifestyleCount > 1 ? `(${lifestyleCount})` : ''}:</h5>
                        <p>{renderUnknowns(lifestyleCount, correctLifestyle)}</p>
                    </div>
                </div>
                
                <div className="behaviorItm"><div className="behaveAnswr"></div></div>
                <div className="behaviorItm">
                <div className="behaveAnswr"><h5 style={domesticatedStyle}>Domesticated? {correctDomesticated === null ? correctDomesticated : ''}</h5></div>
                </div>
                <div className="behaviorItm"><div className="behaveAnswr2"></div></div>
                
            </div>
            <div className="colorNweight">
                <ColorCard
                    correctColors={correctColors}
                    guessedColors={guessedColors}
                    incorrectColors={incorrectColors}
                    colorCount={colorCount}
                    hasMostColors={hasMostColors}
                />
                <div className="weightCard">
                    <b className="miniH2">Weight:</b>
                    <div className="weightMessage" style={formatWeightMessage(weightMessage).style}>
                        {formatWeightMessage(weightMessage).text}
                    </div>
                </div>
            </div>
            </div>
            {isModalOpen && (
    <FullArticleModal 
        articleHeading={articleHeading} 
        articleContent={fullArticle} 
        thumbnail={thumbnail} // Add this line to pass the thumbnail
        onClose={() => setIsModalOpen(false)} 
    />
)}
        </div>
    );
};

Board.propTypes = {
    correctContinents: PropTypes.array.isRequired,
    guessedContinents: PropTypes.array.isRequired,
    incorrectContinents: PropTypes.array.isRequired,
    guessedHabitatType: PropTypes.array,
    correctHabitatType: PropTypes.array,
    sessionAnimalHabitatTypes: PropTypes.array,
    correctHabitats: PropTypes.array.isRequired,
    guessedHabitats: PropTypes.array.isRequired,
    incorrectHabitats: PropTypes.array.isRequired,
    taxonomySummary: PropTypes.string.isRequired,
    taxonomyImageUrl: PropTypes.string,
    taxonomyHeading: PropTypes.string.isRequired,
    correctDiet: PropTypes.string, 
    correctFavoriteFoods: PropTypes.array, 
    correctGroupBehaviors: PropTypes.array, 
    correctLifestyles: PropTypes.array, 
    correctColors: PropTypes.array.isRequired,
    guessedColors: PropTypes.array.isRequired,
    incorrectColors: PropTypes.array.isRequired,
    colorCount: PropTypes.number.isRequired,
    incorrectHabitatTypes: PropTypes.array,
    hasMostColors: PropTypes.bool.isRequired,
    correctDomesticated: PropTypes.bool,
    domesticatedStyle: PropTypes.object.isRequired,
    weightMessage : PropTypes.string.isRequired,
    weightUnit: PropTypes.string.isRequired,
};

export default Board;
