// src/pages/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NavBar from '../components/NavBar.jsx'

const NotFound = () => {
  return (
    <div>
            <Helmet>
                <title>404 Page not found - Wildguesser</title>
                <meta name="description" content="Oops! The page you are looking for does not exist. Return to WildGuesser and continue exploring." />
            
                {/*<!-- Open Graph / Facebook -->*/}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://wildguesser.com/404"/>
                <meta property="og:title" content="404 Page not found - Wildguesser"/>
                <meta property="og:description" content="Oops! The page you are looking for does not exist. Return to WildGuesser and continue exploring."/>
                <meta property="og:image" content="logoetc/logofull.png"/>

                {/*<!-- Twitter -->*/}
                <meta property="twitter:card" content="logoetc/logofull.png"/>
                <meta property="twitter:url" content="https://wildguesser.com/404"/>
                <meta property="twitter:title" content="404 Page not found - Wildguesser"/>
                <meta property="twitter:description" content="Oops! The page you are looking for does not exist. Return to WildGuesser and continue exploring."/>
                <meta property="twitter:image" content="logoetc/logofull.png"/>        
            
            </Helmet>

      <NavBar/>
    <div className="ToSbody"
    style={{display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px"

    }}>

                <Link to={"/"}>
                    <img className="logoOther" src="/logoetc/logofullsvg5.svg" alt="Home" />
                </Link>
             
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
    </div>
 
  );
};

export default NotFound;