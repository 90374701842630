import React, { useEffect } from 'react';
import '../styles/game/Popups.css';

const GuessPopup = ({ message, type, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose(); // Ensure to call a function to set guess message to null or manage visibility
        }, 1000); // Popup shows for 1 second

        return () => {
            clearTimeout(timer);
        };
    }, [onClose]);

    const getTextColor = () => {
        if (type === 'weight') {
            if (message.toLowerCase().includes('incorrect')) {
                return '#ff9f8a';
            } else if (message.toLowerCase().includes('correct')) {
                return '#a0d39c';
            } else if (message.toLowerCase().includes('close')) {
                return '#FFD580';
            }
        }
        return ''; // Default case, no specific text color
    };

    // Apply dynamic text color
    const textColor = getTextColor();

    return (
        <div className={`guess-popup ${type}`} style={{ color: textColor }}>
            {message}
        </div>
    );
};

export default GuessPopup;