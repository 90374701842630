import React, { useState, useEffect } from 'react';
import '../styles/other/Other.css'
import { Link } from 'react-router-dom';
import Footer from "../components/Footer.jsx";
import { Helmet } from 'react-helmet';
import NavBar from '../components/NavBar.jsx'

function ToS() {
    return(
      <div>
            <Helmet>
                <title>Terms of Service - Wildguesser</title>
                <meta name="description" content="Read the terms and conditions for using Wildguesser, including user responsibilities and guidelines." />
           
                {/*<!-- Open Graph / Facebook -->*/}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://wildguesser.com/terms"/>
                <meta property="og:title" content="Terms of Service - Wildguesser"/>
                <meta property="og:description" content="Read the terms and conditions for using Wildguesser, including user responsibilities and guidelines."/>
                <meta property="og:image" content="logoetc/logofull.png"/>

                {/*<!-- Twitter -->*/}
                <meta property="twitter:card" content="logoetc/logofull.png"/>
                <meta property="twitter:url" content="https://wildguesser.com/terms"/>
                <meta property="twitter:title" content="Terms of Service - Wildguesser"/>
                <meta property="twitter:description" content="Read the terms and conditions for using Wildguesser, including user responsibilities and guidelines."/>
                <meta property="twitter:image" content="logoetc/logofull.png"/>   
           
            </Helmet>

        <NavBar/>
        <div className="ToSbody">


            <h1>Terms and Conditions</h1>
      {/*<h2>Company Name: Wildguesser</h2>*/}
      
      <h3>1. Introduction</h3>
      <p>
        Welcome to Wildguesser (“Company”, “we”, “our”, “us”). These Terms and Conditions (“Terms”) govern your use of our web application, services, and content (collectively, the “Services”). By accessing or using our Services, you agree to comply with these Terms.
      </p>
      
      <h3>2. Acceptance of Terms</h3>
      <p>
        Accessing our website at <a href="http://wildguesser.com">http://wildguesser.com</a> implies your agreement to abide by these Terms, relevant laws and regulations, and confirms your responsibility to comply with local laws. If you disagree with any of these Terms, you must not use or access this site. The content on this website is protected under copyright and trademark laws.
      </p>
      
      <h3>3. Use License</h3>
      <p>
        You are granted a temporary, non-transferable license to download one copy of the materials (information or software) from Wildguesser's website for personal, non-commercial viewing. This license does not allow you to:
        <ul>
          <li>Modify or copy the materials;</li>
          <li>Use the materials for any commercial or public display;</li>
          <li>Attempt to decompile or reverse engineer any software on our website;</li>
          <li>Remove any copyright or proprietary notices from the materials;</li>
          <li>Transfer the materials to another person or "mirror" the materials on any other server.</li>
        </ul>
        Violation of these terms may result in automatic termination of this license, at which point you must destroy any downloaded materials, whether in electronic or printed form.
      </p>
      
      <h3>4. Donations</h3>
      <p>
        Donations made through our website are handled by third parties. We are not responsible for the handling of data by these third parties. All donations are non-refundable.
      </p>
      
      <h3>5. User Eligibility</h3>
      <p>
        All users must be over 13 years of age or have consent from a parent or guardian to use our Services.
      </p>
      
      <h3>6. Privacy Policy</h3>
      <p>
        Your privacy is of utmost importance to us. Here is how we handle your information:
        <ul>
          <li><strong>Collection of Information:</strong> We collect personal information lawfully and where appropriate, with consent. This includes data provided directly by you and data collected automatically (such as through cookies).</li>
          <li><strong>Use of Information:</strong> We use the information to provide, maintain, and improve our Services.</li>
          <li><strong>Sharing of Information:</strong> We may share your information with third-party vendors and service providers who perform services on our behalf, as required by law, or to protect our rights.</li>
        </ul>
      </p>
      
      <h3>7. Cookies and Advertising</h3>
      <p>
        We partner with third-party advertisers to serve ads on our website. These companies may use information about your visits to 
        this and other websites to provide advertisements about goods and services of interest to you. Third parties may place and read cookies 
        on your browser or use web beacons to collect information as a result of ad serving on our website. For more information on Google’s use 
        of data, visit <a href="http://www.google.com/policies/privacy/partners/">How Google uses data when you use our partners’ sites or apps</a>.
      </p>
      
      <h3>8. Disclaimer</h3>
      <p>
        Materials on Wildguesser's website are provided "as is." Wildguesser disclaims all warranties, explicit or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement of intellectual property.
      </p>
      
      <h3>9. Limitation of Liability</h3>
      <p>
        In no event will Wildguesser or its suppliers be liable for any damages arising from the use or inability to use the materials on Wildguesser's website, even if advised of the possibility of such damage. Some jurisdictions do not allow limitations on implied warranties or liability for incidental damages, so these limitations may not apply to you.
      </p>
      
      <h3>10. Material Accuracy</h3>
      <p>
        The materials appearing on Wildguesser’s website could contain errors. Wildguesser does not guarantee the accuracy, completeness, or currentness of any materials on its website and may update the materials at any time without notice.
      </p>
      
      <h3>11. External Links</h3>
      <p>
        Wildguesser has not reviewed all sites linked to its website and is not responsible for the contents of any such linked sites. The inclusion of any link does not imply endorsement by Wildguesser.
      </p>
      
      <h3>12. Amendments to Terms</h3>
      <p>
        Wildguesser may revise these terms of service at any time without notice. By using this website, you are agreeing to be bound by the current version of these Terms.
      </p>
      
      <h3>13. Governing Law</h3>
      <p>
        These Terms are governed by the laws of Norway and you irrevocably consent to the jurisdiction of the courts in that State or location.
      </p>
      
      <h3>Contact Us</h3>
      <p>
        If you have any questions or concerns about these Terms, please contact us at:
      </p>
      <p>
        <strong>Wildguesser</strong></p>
        <p>Email: <a href="mailto:contact@wildguesser.com">contact@wildguesser.com</a></p>
            <p>Read more: <Link to={"/contact"}>wildguesser.com/contact</Link></p>
      
   



      <Footer />
        </div>
        </div>

    );
}

export default ToS;