import React, { useState, useEffect } from 'react';
import '../styles/other/Other.css'
import { Link } from 'react-router-dom';
import Footer from "../components/Footer.jsx";
import { Helmet } from 'react-helmet';
import NavBar from '../components/NavBar.jsx'

function Privacy() {
    return(
        <div>
            <Helmet>
                <title>Privacy Policy - Wildguesser</title>
                <meta name="description" content="Learn how WildGuesser collects, uses, and protects your personal information." />

                {/*<!-- Open Graph / Facebook -->*/}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://wildguesser.com/privacy"/>
                <meta property="og:title" content="Privacy Policy - Wildguesser"/>
                <meta property="og:description" content="Learn how WildGuesser collects, uses, and protects your personal information."/>
                <meta property="og:image" content="logoetc/logofull.png"/>

                {/*<!-- Twitter -->*/}
                <meta property="twitter:card" content="logoetc/logofull.png"/>
                <meta property="twitter:url" content="https://wildguesser.com/privacy"/>
                <meta property="twitter:title" content="Privacy Policy - Wildguesser"/>
                <meta property="twitter:description" content="Learn how WildGuesser collects, uses, and protects your personal information."/>
                <meta property="twitter:image" content="logoetc/logofull.png"/>   

            </Helmet>
            <NavBar/>
        
        <div className="ToSbody">


            <h1>Privacy Policy</h1>
            <p>Last edited: 06.05.24</p>

            <p>Welcome to WildGuesser. This Privacy Policy explains how we collect, use, and protect your information when you visit and use our website. WildGuesser is suitable for users of all ages.</p>

            <h2>1. Information We Collect</h2>
            <h3>1.1. Personal Information</h3>
            <p>We do not collect any personal information from our users directly. However, when you interact with our website, we may automatically collect certain information about your device and browsing behavior. This may include:</p>
            <ul>
                <li>IP address</li>
                <li>Browser type</li>
                <li>Operating system</li>
                <li>Referring website</li>
                <li>Pages you visit</li>
                <li>Time and date of visit</li>
            </ul>

            <h3>1.2. Cookies and Tracking Technologies</h3>
            <p>We use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small data files that are placed on your device. They allow us to:</p>
            <ul>
                <li>Remember your preferences</li>
                <li>Understand how you use our site</li>
                <li>Display relevant advertisements</li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <p>We use the information we collect for various purposes, including:</p>
            <ul>
                <li>To improve our website and services</li>
                <li>To understand how users interact with our site</li>
                <li>To display personalized content and advertisements</li>
            </ul>

            <h2>3. Sharing Your Information</h2>
            <p>We do not sell, trade, or otherwise transfer your information to outside parties except in the following circumstances:</p>
            <ul>
                <li>We may share information with service providers who assist us in operating our website and providing services to you, subject to confidentiality agreements.</li>
                <li>We may disclose information if required by law or to protect our rights, property, or safety.</li>
            </ul>

            <h2>4. Third-Party Services</h2>
            <p>Our website may contain links to third-party websites and services. We are not responsible for the privacy practices or the content of these third-party sites. We encourage you to read the privacy policies of any third-party services you use.</p>

            <h2>5. Google AdSense</h2>
            <p>We use Google AdSense to display advertisements on our website. Google AdSense may use cookies and web beacons to collect information about your visits to this and other websites in order to provide advertisements about goods and services of interest to you. For more information about how Google uses data, please visit the <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener noreferrer">Google Privacy & Terms</a> page.</p>

            <h2>6. Security</h2>
            <p>We implement appropriate security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no internet transmission is ever completely secure or error-free. Please keep this in mind when disclosing any information online.</p>

            <h2>7. Children's Privacy</h2>
            <p>Our website is suitable for users of all ages. However, if we become aware that we have inadvertently received personal information from a child under the age of 13, we will delete such information from our records. We encourage parents and guardians to monitor their children’s use of the internet and to help enforce this Privacy Policy by instructing their children never to provide personal information on our website without their permission.</p>

            <h2>8. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.</p>

            <h2>9. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at:</p>
            <p>Email: <a href="mailto:contact@wildguesser.com">contact@wildguesser.com</a></p>
            <p>Read more: <Link to={"/contact"}>wildguesser.com/contact</Link></p>
   



            <Footer />
        </div>
        </div>

    );
}

export default Privacy;