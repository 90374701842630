import React, { useState, useEffect } from 'react';
import "../styles/Home/Home.css";
import "../styles/Home/navBar.css";
import Footer from "../components/Footer.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaw, faPlay } from '@fortawesome/free-solid-svg-icons';

import DifficultyPopup from '../components/DifficultyPopup.jsx'; // Import the DifficultyPopup component
import NavBar from '../components/NavBar.jsx'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Home() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);



    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <div className="HomeDiv">
            <Helmet>
                <title>WildGuesser - Guess The Animal</title>
                <meta name="description" content="Wildguesser is an animal guessing game that offers challenges and fun for all ages. Play now to test your animal knowledge and become a wildlife expert!"/>
            
                {/*<!-- Open Graph / Facebook -->*/}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://wildguesser.com/"/>
                <meta property="og:title" content="WildGuesser - Guess The Animal"/>
                <meta property="og:description" content="Wildguesser is an animal guessing game that offers challenges and fun for all ages. Play now to test your animal knowledge and become a wildlife expert!"/>
                <meta property="og:image" content="logoetc/logofull.png"/>

                {/*<!-- Twitter -->*/}
                <meta property="twitter:card" content="logoetc/logofull.png"/>
                <meta property="twitter:url" content="https://wildguesser.com/"/>
                <meta property="twitter:title" content="WildGuesser - Guess The Animal"/>
                <meta property="twitter:description" content="Wildguesser is an animal guessing game that offers challenges and fun for all ages. Play now to test your animal knowledge and become a wildlife expert!"/>
                <meta property="twitter:image" content="logoetc/logofull.png"/>
            
            </Helmet>
            {/*<div className="hBckRnd"> </div>*/}
            
                <img src="/background/birdBackground.webp" className="HomeBackground" alt="background"/>
                
            
            <div className="bckColor"/>
            <NavBar/>

            <div className="homeContentDiv">
            
            
            <div className="homeHero">
            <img className="logoH" src="/logoetc/logofullsvg5.svg" alt="Logo" />
            <div className="homeCenterDiv">
                <div className="homeText">
                    <h1>THE ANIMAL GUESSING GAME</h1>
                    <p className="Hintro">Test your <strong>wildlife</strong> knowledge and <strong>guess the random mystery animal</strong> based on facts about its habitat, 
                    diet, and more. Take a <strong>wild guess</strong> and see if you are correct. Choose a difficulty level and see how well you know the <strong>animal kingdom!</strong></p>
                </div>
                <div className="centerCenterDiv">
                    <div className="HomeBtnCont">
                        
                        <button className="HomeBtn" onClick={openPopup} style={{ backgroundColor: "#31ad6f" }}>
                            <FontAwesomeIcon className="hPlay" icon={faPaw} />
                            PLAY NOW!
                        </button>
                        
                        
                        <DifficultyPopup isOpen={isPopupOpen} closeModal={closePopup} />
                    </div>


                    

                </div>
            </div>
            <div className="homeInfo">

            <div className="infoCont">
                    <div className="infoHeading"><h3><img className="catImg" src="/catIcons/howto.svg" alt="how to play icon"/> How to play</h3></div>
                    <div className="infoContentWrapper">
                        <div className="infoContentCont">
                            <p><b>This is how you play the game:</b> </p>
                            <p>1. <b>Click</b> on a dropdown button:</p>
                            <img src="/tutorial/click.png" alt="click" className="tutorialImg"></img>
                            <p>2. <b>Choose</b> an option from the dropdown list:</p>
                            <img src="/tutorial/choose.png" alt="choose" className="tutorialImg"></img>
                            <p>3. If your guess is correct, it will appear on the relevant <b>card</b>:</p>
                            <img src="/tutorial/card.png" alt="card" className="tutorialImg"></img>
                            <p>4. When you have enough information about the animal you can guess its name using the <b>guess-input</b>:</p>
                            <img src="/tutorial/guess.png" alt="guess" className="tutorialImg2"></img>
                            <i className="guessinfo">Here the user guessed "Dog".</i>

                            <h3>Tips for playing:</h3>
                            <b>Guess taxonomy in order:</b>

                            <p><b>1. Guess the Class First:</b> Start by guessing the class. This will filter the possible orders and 
                                families to only those within the guessed class.
                            </p>
                            <p><b>2. Guess the Order Next:</b> After identifying the class, guess the order. This will further narrow 
                                down the families to those within the guessed order.
                            </p>
                            <p><b>3. Guess the Family Last:</b> Finally, guess the family. With the previous filters in place, you're 
                                more likely to make a correct guess.
                            </p>
                            <p>By following this sequence—class, order, then family—you increase your chances of making accurate guesses.</p>
                            <p style={{color: '#a0d39c'}}><Link to={"/guide"} style={{ textDecoration: 'underline', color: '#a0d39c' }}>Click here for a more in depth guide</Link></p>
                        </div>
                    </div>
                </div>
                
                <div className="infoCont">
                    <div className="infoHeading"><h3><img className="catImg" src="/catIcons/information.svg" alt="information icon"/> Information</h3></div>
                    <div className="infoContentWrapper">
                        <div className="infoContentCont">
                            <p><strong>Wildguesser</strong> is a free online animal guessing game.</p>
                            <p>The animals in the database are mainly a mix of genuses and species. If a subspecies is mentioned, the higher classification is also considered correct. For instance, identifying a 'wolf' correctly applies when 'dog' is the answer.</p>
                            <p>We suggest starting with the easy difficulty to help you learn the game before giving the other difficulties a try.</p>
                            <p><b style={{color: "#31ad6f"}}>Easy:</b> This gamemode includes domesticated and safari animals that most people have heard about.</p>
                            <i> Examples: Dog, Sheep, Cat, Lion</i>
                            <p><b style={{color: "#FFCC00"}}>Medium:</b> This gamemode is for those with some knowledge about animals, featuring a mix of familiar and slightly exotic creatures.  </p>
                            <i>Examples: Brown Bear, Anaconda, Emu, Cheetah</i> 
                            <p><b style={{color: "#BA3911"}}>Hard:</b> This gamemode is designed for animal experts, with more obscure and exotic animals.</p>
                            <i>Examples: Mandrill, Caracal, Argali</i>
                        </div>
                    </div>
                </div>
                <div className="infoCont">
                    <div className="infoHeading"><h3><img className="catImg" src="/catIcons/news.svg" alt="news icon"/> News</h3></div>
                    <div className="infoContentWrapper">
                        <div className="infoContentCont">
                            <p><b>Features that we are working on or want to add in the future:</b></p>
                            <ul className="NewsList">
                                <li>Login system</li>
                                <li>Leaderboards</li>
                                <li>Multiplayer</li>
                                <li>More game modes</li>
                                <li>Ability to filter game modes</li>
                                <li>Community game modes</li>
                            </ul>
                        </div>
                    </div>
    
    
                </div>
                </div>
                

            </div>
            <div className="infoNupcomming">
                <div className="moreInfo">
                    <div className="moreInfoImgTxt">
                        <img src="/homeImg/guess.jpg" className="infoImg" alt="guess"/>
                        <div className="moreInfoItm">
                            <h2>Guess the Animal</h2>
                            <p>Dive into the animal kingdom with Wildguesser! Each round presents a unique challenge to identify mysterious animals from around the globe. 
                                Whether you’re a seasoned expert or just starting out, each guess helps you learn more about wildlife in the most fun way possible. 
                                Test your skills and see how quickly you can guess the animal based on a series of clues related to its habitat, diet, and behavior.</p>
                        </div>
                    </div>

                    <div className="moreInfoImgTxt">
                        <img src="/homeImg/learn.jpg" className="infoImg" alt="learn"/>
                        <div className="moreInfoItm">
                            <h2>A Fun Way to Learn Animal Facts</h2>
                            <p>Alongside reading about animals, you can interact with them through our game! Engage with our interactive features that make learning about wildlife an adventure. 
                                Through our game you will learn interesting facts about animals. Guess the animal, educate yourself and enhance your knowledge as you play.</p>
                        </div>
                    </div>

                    <div className="moreInfoImgTxt">
                    <img src="/homeImg/trivia.jpg" className="infoImg" alt="trivia"/>
                        <div className="moreInfoItm">
                            <h2>Entertaining Animal Trivia</h2>
                            <p>Do you like animal quizzes and trivia? Wildguesser takes it up a notch by testing your knowledge in a dynamic and fun format. 
                                See how high you can score and challenge yourself with the different difficulties that keeps you coming back for more. </p>
                        </div>
                    </div>
                </div>

                <div className="upcomming">
                    <h3>Game Modes Coming to Wildguesser:</h3>
                    <p>At Wildguesser, we're committed to making our platform the ultimate destination for animal-themed gaming. 
                        Our mission is to expand our range of engaging and educational wildlife games, building on the success of 
                        our original animal detective gameplay. Here's a sneak peek at the game modes we're planning to 
                        introduce, designed to challenge your animal knowledge and provide endless fun!</p>
                    <h2>Guess the Animal Sound</h2>
                    <p>Prepare your ears for a wild auditory experience with our "Guess the Animal Sound" game mode. 
                        Perfect for auditory learners and sound enthusiasts, this game will challenge players to identify animals based solely on 
                        audio clues. From the roaring of a lion to the chirping of birds, test how well you can recognize animals by their unique 
                        sounds. This immersive wildlife game will enhance your auditory skills and deepen your appreciation for the natural world's 
                        diverse vocalizations.</p>
                    <h2>Animal Connections</h2>
                    <p>Dive into the exciting world of Animal Connections, a daily word association game that tests your knowledge and quick 
                        thinking! Each day, you'll be presented with a fresh set of sixteen animal-related words. Your challenge is to group 
                        these words into four categories of four words each, based on shared characteristics or themes. Whether it's categorizing 
                        animals by habitat, behavior, or unique traits, Animal Connections offers a fun and educational way to explore the animal 
                        kingdom. Perfect for animal lovers and puzzle enthusiasts alike, this game will keep you coming back every day for a new, 
                        engaging challenge.</p>
                    <h2>Animal Wordle</h2>
                    <p>Get ready for "Wildle," a thrilling new twist on the popular word-guessing game Wordle. In this mode, players will 
                        receive cryptic clues and limited attempts to guess a mystery animal name. Each correct guess brings fun facts and trivia 
                        about the animal, making it a perfect blend of challenge and learning. Whether you're a word puzzle enthusiast or a wildlife 
                        lover, Animal Wordle promises to be a captivating addition to your daily gaming routine.</p>
                    <h2>Animal Quizzes and Trivia</h2>
                    <p>Dive deeper into the animal kingdom with our comprehensive "Animal Quizzes and Trivia." This game mode features a wide 
                        range of questions about animal habits, habitats, and obscure facts. Challenge your knowledge and compete with friends 
                        to see who truly knows the most about the natural world. Our quizzes are designed to be both informative and entertaining, 
                        making learning about wildlife an engaging experience.</p>
                        
                </div>
            </div>
            {/*}
            <button className="donateBtnH">Donate</button>
                <i>50% of the donation money goes towards animal conservation.</i>*/}
            <Footer />

            </div>

        </div>

    );
}

export default Home;
