import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

function Dropdown({ title, items, isOpen, setIsOpen, onSelect, disabled, colorCircles }) {
    const [loadedIcons, setLoadedIcons] = useState({});
    const loadedItems = useRef({});
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(null);
            }
        };

        if (isOpen === title) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, title, setIsOpen]);

    useEffect(() => {
        if (isOpen === title && title !== 'Colors' && !loadedItems.current[title]) {
            items.forEach(item => {
                const baseName = item.toLowerCase().replace(/\s+/g, '');
                const icons = [];
                let loadedIconsForItem = [];

                for (let i = 1; i <= 4; i++) {
                    const imageUrl = `/dropdownIcons/${baseName}${i}.svg`;
                    icons.push(loadImage(imageUrl));
                }

                Promise.all(icons).then(results => {
                    loadedIconsForItem = results.filter(Boolean);
                    setLoadedIcons(prevState => ({
                        ...prevState,
                        [item]: loadedIconsForItem
                    }));
                    loadedItems.current[title] = true;
                });
            });
        }
    }, [isOpen, items, title]);

    const loadImage = (url) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(url);
            img.onerror = () => resolve(null);
        });
    };

    return (
        <div className="btnNlistCont" ref={dropdownRef}>
            <button
                onClick={() => !disabled && setIsOpen(isOpen === title ? null : title)}
                className="dropdown-button"
                disabled={disabled}
                style={{
                    backgroundColor: disabled ? 'gray' : '',
                    color: disabled ? 'lightgray' : 'black',
                    cursor: disabled ? 'default' : 'pointer',
                }}
            >
                {title} <FontAwesomeIcon className="chevron" icon={faAngleDown} />
            </button>
            {isOpen === title && !disabled && (
                <ul className="dropdown-menu">
                    {items.map((item, index) => (
                        <li key={index} onClick={() => {
                            onSelect(item);
                            setIsOpen(null);
                        }}>
                            {item}
                            {title === 'Colors' ? (
                                <div className="dropdownColorCont">
                                    {colorCircles[item] ? colorCircles[item].map((color, colorIndex) => (
                                        <span
                                            key={colorIndex}
                                            style={{
                                                display: 'inline-block',
                                                width: '20px',
                                                height: '20px',
                                                backgroundColor: color,
                                                borderRadius: '50%',
                                                margin: '0 5px',
                                                border: '1px solid black'
                                            }}
                                        />
                                    )) : null}
                                </div>
                            ) : (
                                <div className="dropdownIconCont">
                                    {loadedIcons[item] && loadedIcons[item].map((icon, iconIndex) => (
                                        <img key={iconIndex} src={icon} alt={`${item} icon`} className="dropdownIcons" />
                                    ))}
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default Dropdown;
