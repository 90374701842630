// src/components/AllLinks.js
import React from 'react';
import { Link } from 'react-router-dom';

const AllLinks = () => (
  <div style={{ display: 'none' }}>
    <Link to="/">Home</Link>
    <Link to="/terms">Terms</Link>
    <Link to="/contact">Contact</Link>
    <Link to="/about">About</Link>
    <Link to="/privacy">Privacy</Link>
    <Link to="/guide">Guide</Link>
    <Link to="/404.html">404</Link>
    <Link to="/play/easy">Play Easy</Link>
    <Link to="/play/medium">Play Medium</Link>
    <Link to="/play/hard">Play Hard</Link>
    <Link to="/donations">Donations</Link>
  </div>
);

export default AllLinks;
