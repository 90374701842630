import React from 'react';
import PropTypes from 'prop-types';
import '../styles/game/Board.css';

const GenericCard = ({ title, correctItems, guessedItems, incorrectItems, itemCount, itemType, backgroundColor }) => {
    //console.log(`Rendering ${title} card`);
    //console.log("correctItems:", correctItems);
    //console.log("guessedItems:", guessedItems);
    //console.log("incorrectItems:", incorrectItems);

    const renderTitle = () => {
        if (correctItems.includes('global')) {
            return `Lives in 5-7 continents`;
        } else if (itemCount === 1) {
            return `Lives in 1 ${title.slice(0, -1)}`; // Singular form
        } else {
            return `Lives in ${itemCount} ${title}`;
        }
    };

    return (
        <div className="mCard" style={{ backgroundColor }}>
            <img src="/png/redpin2.png" className="pushPin" alt="pushpin"></img>
            
            <h3>{renderTitle()}</h3>
            <div className="cardContent">
                <div className="imageContainer">
                    {Array.from({ length: itemCount }, (_, i) => (
                        <div key={i} className="continentItem">
                            <p>{guessedItems[i] || 'Unknown'}</p>
                            <img
                                className="question"
                                src={correctItems[i] ? `/png/${correctItems[i]}.png` : "/png/question.png"}
                                alt={itemType}
                                draggable="false"
                            />
                        </div>
                    ))}
                </div>
                {/*incorrectItems.length > 0 && (
                    <div className="incorrectGuesses">
                        <b>Does not live in:</b>
                        <b>{incorrectItems.join(', ')}</b>
                    </div>
                )*/}
            </div>
        </div>
    );
};

GenericCard.propTypes = {
    title: PropTypes.string.isRequired,
    correctItems: PropTypes.array.isRequired,
    guessedItems: PropTypes.array.isRequired,
    incorrectItems: PropTypes.array.isRequired,
    itemCount: PropTypes.number.isRequired,
    itemType: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
};

export default GenericCard;
