import React, { useState, useEffect } from 'react';
import '../styles/other/Other.css'
import { Link } from 'react-router-dom';
import Footer from "../components/Footer.jsx";
import { Helmet } from 'react-helmet';
import NavBar from '../components/NavBar.jsx'

const WildGuesserTips = () => (
  <div>
                      <Helmet>
                <title>How to Play WildGuesser - Guide</title>
                <meta name="description" content="Discover tips and strategies for mastering Wildguesser, the interactive animal guessing game." />
            
                {/*<!-- Open Graph / Facebook -->*/}
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://wildguesser.com/guide"/>
                <meta property="og:title" content="How to Play WildGuesser - Guide"/>
                <meta property="og:description" content="Discover tips and strategies for mastering Wildguesser, the interactive animal guessing game."/>
                <meta property="og:image" content="logoetc/logofull.png"/>

                {/*<!-- Twitter -->*/}
                <meta property="twitter:card" content="logoetc/logofull.png"/>
                <meta property="twitter:url" content="https://wildguesser.com/guide"/>
                <meta property="twitter:title" content="How to Play WildGuesser - Guide"/>
                <meta property="twitter:description" content="Discover tips and strategies for mastering Wildguesser, the interactive animal guessing game."/>
                <meta property="twitter:image" content="logoetc/logofull.png"/>        
            
            </Helmet>
     <NavBar/>
    <div className="ToSbody">

      <h1>Tips and Tricks for Playing WildGuesser</h1>
      <h2>Introduction</h2>
      <p>
        WildGuesser is an engaging online game that tests your knowledge of the animal kingdom. The goal is to identify an animal based on various clues about its taxonomy, geography, behavior, and attributes. Here’s a comprehensive guide to help you become a WildGuesser master.
      </p>
  
      <h2>How to Play</h2>
      <ul>
        <li><strong>Select a Dropdown</strong>: Start by clicking on a dropdown button.</li>
        <img src="/tutorial/click.png" alt="click" className="tutorialImg3"></img>
        <li><strong>Make a Choice</strong>: Choose an option from the list provided.</li>
        <img src="/tutorial/choose.png" alt="choose" className="tutorialImg3"></img>
        <li><strong>Get Feedback</strong>: If your guess is correct, it will appear on the relevant card.</li>
        <img src="/tutorial/card.png" alt="card" className="tutorialImg3"></img>
        <li><strong>Final Guess</strong>: Once you have enough information, use the guess-input field to name the animal.</li>
        <img src="/tutorial/guess.png" alt="guess" className="tutorialImg22"></img>
      </ul>
  
      <h2>Tips for Effective Guessing</h2>
      <h3>1. <img src="catIcons/taxonomy.svg" alt="taxonomy" className="guideIcons"/> Taxonomy</h3>
      <ul>
        <li><strong>Class</strong>: Begin by guessing the class. This step narrows down the possible orders and families, making subsequent guesses more precise.</li>
        <li><strong>Order</strong>: After identifying the class, move on to guessing the order. This further refines the pool of possible families.</li>
        <li><strong>Family</strong>: Finally, guess the family. With the class and order already known, your chances of making a correct family guess increase significantly.</li>
      </ul>
      <p>By following the taxonomy sequence (class, order, family), you systematically narrow down the possibilities.</p>
  
      <h3>2. <img src="catIcons/geography.svg" alt="geography" className="guideIcons"/> Geography</h3>
      <ul>
        <li><strong>Continents</strong>: Guess the continents where the animal lives. This helps eliminate animals that do not inhabit those regions.</li>
        <li><strong>Habitat Types and Specific Habitats</strong>: Knowing whether the animal is terrestrial, arboreal, or semi-aquatic, and the specific habitat it resides in (e.g., grassland) can provide crucial hints.</li>
        
      
      </ul>
  
      <h3>3. <img src="catIcons/behavior.svg" alt="behavior" className="guideIcons"/> Behavior</h3>
      <ul>
        <li><strong>Diet</strong>: Determine if the animal is a carnivore, herbivore, or omnivore. This can be a significant clue, as it ties directly to the animal's lifestyle.</li>
        <li><strong>Group Behavior</strong>: Identify whether the animal is solitary or social.</li>
        <li><strong>Lifestyle</strong>: Knowing if the animal is diurnal, nocturnal or crepuscular can further narrow down your options. Diurnal animals are animals that are active during the day. Nocturnal animals are active at night. Crepuscular animals are most active during dawn and dusk.</li>
        <li><strong>Domestication</strong>: Some animals are domesticated, which can be an easy clue for more common animals.</li>
      </ul>
  
      <h3>4. <img src="catIcons/attributes.svg" alt="attributes" className="guideIcons"/> Attributes</h3>
      <ul>
        <li><strong>Colors</strong>: Identifying the animal's color can help in visualizing and guessing the correct animal.</li>
        <li><strong>Weight</strong>: Estimating the weight range can differentiate between similar animals.</li>
      </ul>
  
      <h2>Game Modes</h2>
      <ul>
        <li><strong>Easy</strong>: Ideal for beginners, featuring familiar domesticated and safari animals like dogs, sheep, and lions.</li>
        <li><strong>Medium</strong>: Suited for players with some animal knowledge, includes a mix of familiar and slightly exotic creatures such as cheetahs, sea lions, and green anacondas.</li>
        <li><strong>Hard</strong>: Designed for animal experts, this mode features obscure and exotic animals like caracals, ibex, and coatis.</li>
      </ul>

      <h2>Tips for Guessing Continents:</h2>
      <div className="guessContDiv">
        <img src="tutorial/1cont.png" alt="1 continent" className="guideContImg"/>
        <p><b>1 Continent:</b> If an animal only lives on one continent, the least likely option is Europe since most European animals also inhabit parts of Asia. While it's possible for an animal to be exclusive to Europe, it is less common.</p>
      </div>

      <div className="guessContDiv">
        <img src="tutorial/2cont.png" alt="2 continents" className="guideContImg"/>
        <p><b>2 Continents:</b> When an animal lives on two continents, it is likely that these continents are connected. Common pairs include Asia and Africa, Asia and Europe, and North and South America. While this isn't always the case, it is a strong possibility. If the continents are not connected, they likely have similar habitats.</p>
      </div>     
      
      <div className="guessContDiv">
        <img src="tutorial/3cont.png" alt="3 continents" className="guideContImg"/>
        <p><b>3 Continents:</b> An animal found on three continents typically lives either on three connected continents (such as Europe, Africa, and Asia) or on two connected continents plus one overseas. Both scenarios are equally probable. It could also be present on three unconnected continents (e.g., South America, Africa, and Oceania), but this is less likely. The most common variations are Africa, Asia, and Europe, or North America, Europe, and Asia.</p>
      </div>

      <div className="guessContDiv">
        <img src="tutorial/4cont.png" alt="4 continents" className="guideContImg"/>
        <p><b>4 Continents:</b> Animals living on four continents are often widespread across large landmasses. Likely combinations include Africa, Asia, Europe, and North America, or North and South America along with Europe and Asia.</p>
      </div>

      <div className="guessContDiv">
        <img src="tutorial/global.png" alt="5+ continents" className="guideContImg"/>
        <p><b>5+ Continents/Global:</b> Animals found on five or more continents are most likely domesticated, as this widespread distribution is uncommon for wild species.</p>
      </div>

      
      
      
      <h3>Why Oceania?</h3>
      <p>In our game, we use 'Oceania' instead of 'Australia' to include a wider array of regions. While Oceania is not a continent, this term allows us to encompass additional important areas like New Zealand and Papua New Guinea, which are not part of the Australian continent. This ensures our game covers all significant territories in the region.</p>


      <h2>Habitat Descriptions and Categories</h2>
      <p>Each animal in the game is assigned to its primary habitats. While animals may inhabit more locations, we've limited the display to 4-5 habitats per animal to conserve screen space. This approach ensures that the game remains visually clear without compromising on essential habitat information.</p>
      <div className="habitatGuide">

      <div className="guideImgNText">
        <img src="png/taiga.png" className="guideImgs" alt="taiga"/>
        <div>
          <h3>Taiga</h3>
          <p>Taiga habitats, also known as boreal forests, are characterized by coniferous forests and long, cold winters. These forests usually consists of pines, spruces, and/or larches</p>
          <p><strong>Habitats:</strong> Boreal forests, subarctic forests</p>
        </div>
      </div>

      <div className="guideImgNText">
        <img src="png/temperate-forest.png" className="guideImgs" alt="temperate forest"/>
        <div>
          <h3>Temperate Forest</h3>
          <p>Temperate forest habitats are found in regions with moderate temperatures and high precipitation, dominated by deciduous or mixed forests.</p>
          <p><strong>Habitats:</strong> Deciduous forests, mixed forests, coniferous forests, temperate rainforests</p>
        </div>
      </div>

      <div className="guideImgNText">
        <img src="png/tropical-forest.png" className="guideImgs" alt="tropical forest"/>
        <div>
          <h3>Tropical Forest</h3>
          <p>Tropical forest habitats are found near the equator, characterized by high biodiversity, warm temperatures, and high rainfall.</p>
          <p><strong>Habitats:</strong> Tropical rainforests, dry rainforest, tropical deciduous forests, cloud forests</p>
        </div>
      </div>


      <div className="guideImgNText">
        <img src="png/desert.png" className="guideImgs" alt="desert"/>
        <div>
          <h3>Desert</h3>
          <p>Desert habitats are characterized by low precipitation, extreme temperatures, and sparse vegetation.</p>
          <p><strong>Habitats:</strong> Sand dunes, rocky deserts, semi-arid regions</p>
        </div>
      </div>


      <div className="guideImgNText">
        <img src="png/freshwater.png" className="guideImgs" alt="freshwater"/>
        <div>
          <h3>Freshwater</h3>
          <p>Freshwater habitats include inland water bodies that are not saline. These environments support a variety of plant and animal life.</p>
          <p><strong>Habitats:</strong> Rivers, lakes, ponds, streams, swamps, marshes</p>
        </div>
      </div>


      <div className="guideImgNText">
        <img src="png/grassland.png" className="guideImgs" alt="grassland"/>
        <div>
        <h3>Grassland</h3>
        <p>Grassland habitats are open areas dominated by grasses and other herbaceous plants, with few trees.</p>
        <p><strong>Habitats:</strong> Savannas, prairies, steppes, shrubland</p>
        </div>
      </div>


      <div className="guideImgNText">
        <img src="png/mountainous.png" className="guideImgs" alt="mountainous"/>
        <div>
          <h3>Mountainous</h3>
          <p>Mountainous habitats are regions with high elevation, characterized by rugged terrain and often cooler temperatures.</p>
          <p><strong>Habitats:</strong> Alpine meadows, montane forests, rocky slopes</p>
        </div>
      </div>

      <div className="guideImgNText">
        <img src="png/open-ocean.png" className="guideImgs" alt="open ocean"/>
        <div>
          <h3>Open Ocean</h3>
          <p>Open ocean habitats are vast, deep-water areas far from the coast, characterized by high salinity and a wide range of marine life.</p>
          <p><strong>Habitats:</strong> Pelagic zones, deep-sea environments</p>
        </div>
      </div>


      <div className="guideImgNText">
        <img src="png/coastal.png" className="guideImgs" alt="coastal"/>
        <div>
          <h3>Coastal</h3>
          <p>Coastal habitats include the areas where the land meets the ocean, encompassing both the shoreline and the immediate offshore areas.</p>
          <p><strong>Habitats:</strong> Beaches, cliffs, dunes, tidal marshes, estuaries, neritic ocean</p>
          <div className="guideSpace"></div>
        </div>
      </div>


      <div className="guideImgNText">
        <img src="png/polar.png" className="guideImgs" alt="polar"/>
        <div>
          <h3>Polar</h3>
          <p>Polar habitats are located in the Arctic and Antarctic regions, characterized by extreme cold, ice, and snow.</p>
          <p><strong>Habitats:</strong> Ice caps, tundra, polar deserts</p>
        </div>
      </div>


      <div className="guideImgNText">
        <img src="png/rural.png" className="guideImgs" alt="rural"/>
        <div>
          <h3>Rural</h3>
          <p>Rural habitats are areas with low human population density, often associated with agriculture and open space.</p>
          <p><strong>Habitats:</strong> Farmlands, pastures, countryside</p>
        </div>
      </div>


      <div className="guideImgNText">
        <img src="png/urban.png" className="guideImgs" alt="urban"/>
        <div>
          <h3>Urban</h3>
          <p>Urban habitats are areas with high human population density and infrastructure, including cities and towns.</p>
          <p><strong>Habitats:</strong> Parks, gardens, streets, buildings</p>
        </div>
      </div>

    </div>
    <h2>Guessing an Animal's Weight</h2>
      <h3>How We Describe Weight:</h3>
      <p>We provide a range for the minimum and maximum weight of fully grown animals.</p>
      <p><b>Incorrect guesses:</b> If your guess falls outside the weight range, you'll receive feedback indicating whether your guess was too low or too high.</p>
      <p><b>Correct guesses:</b> A correct guess is one that falls within the animal's weight range. For a correct guess, the weight card will display the possible weight range, for example: 30-90 kg</p>
      <p>You can choose if you want to use kg or lbs for guesses.</p>

      <h2>Diet</h2>
<h3>Diet and Food Information:</h3>
<p>When you correctly identify an animal's diet (carnivore, herbivore, or omnivore), the behavior card will show its favorite food. 
  This includes the food it consumes most frequently or is most famously known for eating. 
  This information can greatly assist you in accurately identifying the animal.</p>
  
      <h2>Detailed Strategies for Success</h2>
      <h3>Start with the Basics</h3>
      <p>Understanding the basic characteristics of animal classes, orders, and families is crucial. For instance, mammals (Class: Mammalia) are 
        distinguished by features such as fur and the presence of mammary glands. Within this class, orders like Rodentia (rodents) and families like 
        Castoridae (beavers) have specific traits. Learning these distinctions will help you make more informed guesses. Reading the information on the taxonomy card can help a lot.</p>
  
      <h3>Utilize Process of Elimination</h3>
      <p>Even incorrect guesses provide valuable information. If you guess a continent and it’s incorrect, you’ve effectively narrowed down the possible correct answers. Apply this process across all categories to systematically eliminate options and zero in on the correct animal.</p>
  
      <h3>Leverage Animal Behaviors</h3>
      <p>Animal behaviors such as diet, group behavior, and lifestyle are significant clues. For example, if you know an animal is a herbivore and diurnal, you can eliminate nocturnal carnivores from your list. Use these behavioral traits to refine your guesses further.</p>
  
      
  
      <h3>Practice with Different Game Modes</h3>
      <p>Start with the Easy mode to get a feel for the game and gradually progress to Medium and Hard modes as you become more confident. Each mode presents unique challenges and learning opportunities.</p>
  
      <h2>Advanced Tips</h2>
      <h3>Study Animal Distribution</h3>
      <p>Knowing the geographic distribution of animals can provide a substantial edge. For example, beavers are found in North America, Europe, and Asia. If you guess continents accurately, you can quickly narrow down your choices.</p>
  
      <h3>Understand Habitat Preferences</h3>
      <p>Different animals have specific habitat preferences. For example, semi-aquatic animals like beavers live in freshwater environments. Recognizing these preferences can lead to quicker, more accurate guesses.</p>
  
      <h2>Conclusion</h2>
      <p>WildGuesser is not just a game but a fun and educational way to learn about the animal kingdom. By employing strategic guessing, utilizing the process of elimination, and leveraging detailed animal knowledge, you can enhance your gameplay experience. Follow these tips and tricks to improve your accuracy and enjoy the challenge that WildGuesser offers. Happy guessing!</p>
      <Footer />
    </div>
    
    </div>
  );
  
  export default WildGuesserTips;
  