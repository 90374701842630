import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/Home/DifficultyPopup.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaw, faTimes } from '@fortawesome/free-solid-svg-icons';

const DifficultyPopup = ({ isOpen, closeModal }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="modalOverlay">
            <div className="modalContent">
                <button className="closeBtn" onClick={closeModal}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <h2>Select Difficulty: </h2>
                <div className="modalBtnCont">
                    <Link to={"/play/easy"} style={{ textDecoration: 'none' }}>
                        <button className="HomeBtn" style={{ backgroundColor: "#31ad6f" }} onClick={closeModal}>
                            <FontAwesomeIcon className="hPaw" icon={faPaw} /> EASY
                        </button>
                    </Link>
                    <div className="dcltyExpl" style={{ color: "#31ad6f" }}>Examples: Dog, Cat, Lion</div>
                    <Link to={"/play/medium"} style={{ textDecoration: 'none' }}>
                        <button className="HomeBtn" style={{ backgroundColor: "#FFCC00" }} onClick={closeModal}>
                            <FontAwesomeIcon className="hPaw" icon={faPaw} /> MEDIUM
                        </button>
                    </Link>
                    <div className="dcltyExpl" style={{ color: "#FFCC00" }}>Examples: Brown Bear, Anaconda, Emu</div>
                    <Link to={"/play/hard"} style={{ textDecoration: 'none' }}>
                        <button className="HomeBtn" style={{ backgroundColor: "#BA3911" }} onClick={closeModal}>
                            <FontAwesomeIcon className="hPaw" icon={faPaw} /> HARD
                        </button>
                    </Link>
                    <div className="dcltyExpl" style={{ color: "#BA3911" }}>Examples: Mandrill, Caracal, Argali</div>
                </div>
            </div>
        </div>
    );
};

export default DifficultyPopup;
