import React from 'react';
import '../styles/Home/Footer.css'
import { Link } from 'react-router-dom';


function Footer() {
    return (
        <div className="footerCont">
            <div className="footer">

            <div className="footerItm"><Link to={"/facts"} style={{ textDecoration: 'none' }}>Facts</Link></div>

                <div className="footerItm"><Link to={"/about"} style={{ textDecoration: 'none' }}>About us</Link></div>

                
                <div className="footerItm"><Link to={"/contact"} style={{ textDecoration: 'none' }}>Contact us</Link></div>

                <div className="footerItm"><Link to={"/privacy"} style={{ textDecoration: 'none' }}>Privacy</Link></div>
                

                
                    <div className="footerItm"><Link to={"/terms"} /*target="_blank"*/style={{ textDecoration: 'none' }}>Terms of service</Link></div>
            </div>
        </div>
      
    );
}

export default Footer;