import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Game from './pages/Game';
import Home from './pages/Home'; // Import the AddAnimal component
import ToS from './pages/ToS';
import Contact from './pages/Contact';
import About from './pages/About';
import Privacy from './pages/Privacy'
import './App.css'; // Global styles
import WildGuesserTips from './pages/WildGuesserTips';
import NotFound from './pages/NotFound';
import AllLinks from './components/AllLinks';
import ScrollToTop from './components/ScrollToTop';
import Donations from './pages/Donations';
import AnimalFacts from './pages/AnimalFacts';



function App() {

  /*useEffect(() => {
    if (typeof window !== "undefined" && window.document) {
      const adsScript = document.createElement('script');
      adsScript.async = true;
      adsScript.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      document.body.appendChild(adsScript);
      adsScript.onload = () => {
        window.adsbygoogle = window.adsbygoogle || [];
        window.adsbygoogle.push({
          google_ad_client: "ca-pub-yourAdSensePublisherID",
          enable_page_level_ads: true
        });
      };
    }
  }, []);*/



  return (
    <Router>
      <AllLinks />
      <ScrollToTop/>
      <div className="App">
        <Routes>
          <Route path="/play/easy" element={<Game difficulty="Easy" />} />
          <Route path="/play/medium" element={<Game difficulty="Medium" />} />
          <Route path="/play/hard" element={<Game difficulty="Hard" />} />
          <Route path="/" element={<Home />} />   
          <Route path="/terms" element={<ToS />} />
          <Route path="/contact" element={<Contact/>}   />
          <Route path="/about" element={<About/>}   />
          <Route path="/privacy" element={<Privacy/>}   />
          <Route path="/guide" element={<WildGuesserTips/>}   />
          <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
          <Route path="/donations" element={<Donations />} />
          <Route path="/facts" element={<AnimalFacts/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;