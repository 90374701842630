import React from 'react';
import PropTypes from 'prop-types';
import '../styles/game/Board.css';

const ColorCard = ({ correctColors, guessedColors, incorrectColors, colorCount, hasMostColors }) => {
    const colorMap = {
        Black: '#000000',
        Brown: '#6F4E37',
        Green: '#008000',
        Red: '#c74430',
        Tan: '#d2b48c',
        Yellow: '#e6be49',
        White: 'white',
        Orange: 'orange',
        Pink: '#ffb7c5',
        Blue: '#0047AB',
    };

    const renderTitle = () => {
        if (hasMostColors) {
            return `Colors: over 6`;
        } else if (colorCount === 1) {
            return `Color: 1 color`;
        } else {
            return `Colors: ${colorCount}`;
        }
    };

    const renderCircles = () => {
        if (hasMostColors) {
            return (
                <div className="colorCircleContainer">
                    <div
                        className="colorCircle"
                        style={{
                            background: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)',
                            border: '1px solid black',
                            filter: 'saturate(40%)',
                            lineHeight: 1,
                        }}
                    >
                        <p className="colorLabel">Most Colors</p>
                    </div>
                </div>
            );
        } else {
            const circles = [];
            for (let i = 0; i < colorCount; i++) {
                const guessedColorName = guessedColors[i] || 'gray';
                const guessedColorCode = colorMap[guessedColorName] || 'gray';
                circles.push(
                    <div key={i} className="colorCircleContainer">
                        <div
                            className="colorCircle"
                            style={{
                                backgroundColor: guessedColorCode,
                                border: '1px solid black',
                            }}
                        >
                            <p className="colorLabel">{guessedColorName !== 'gray' ? guessedColorName : '?'}</p>
                        </div>
                    </div>
                );
            }
            return circles;
        }
    };

    return (
        <div className="mCard2" style={{ backgroundColor: '#595858' }}>
            
            <img src="/png/redpin2.png" className="pushPin" alt="pushpin"></img>
            <h3>{renderTitle()}</h3>
            <div className="cardContent2">
                <div className="circleContainer">{renderCircles()}</div>
            </div>
        </div>
    );
};

ColorCard.propTypes = {
    correctColors: PropTypes.array.isRequired,
    guessedColors: PropTypes.array.isRequired,
    incorrectColors: PropTypes.array.isRequired,
    colorCount: PropTypes.number.isRequired,
    hasMostColors: PropTypes.bool.isRequired,
};

export default ColorCard;
