import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBook, faHouse, faPaw } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import DifficultyPopup from '../components/DifficultyPopup.jsx';
import React, { useState, useEffect } from 'react';

function NavBar() {

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => setDropdownVisible(!dropdownVisible);



    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };


    return(
        <div className="navBar">
            <div className="navLeft">
                <Link to={"/"} style={{ textDecoration: 'none' }}><FontAwesomeIcon className="navLogo" icon={faPaw} /></Link>
                <div>
                    <Link to={"/"} style={{ textDecoration: 'none' }}>
                        <div className="leftLinks"><FontAwesomeIcon className="leftIcon" icon={faHouse} />HOME</div>
                    </Link>
                </div>
                <div>
                    <Link to={"/guide"} style={{ textDecoration: 'none' }}>
                        <div className="leftLinks"><FontAwesomeIcon className="leftIcon" icon={faBook} />GUIDE</div>
                    </Link>
                </div>
                <div className="donateCont">
                    <Link to={"https://donate.stripe.com/28oaHa5xS3e9f7O6oo"} style={{ textDecoration: 'none' }} target="_blank">
                        <button className="donoBtn">Donate</button>
                    </Link>
                    <Link to={"/donations"} style={{ textDecoration: 'none' }}>
                        <div className="donoTCont">
                            <div className="donoText">50% of donations goes to The Nature Conservancy.</div>
                            <div className="readDono">Read more</div>
                        </div>
                    </Link>
                </div>
            </div>


            <div className="navRight">
            <button className="navPlay" onClick={openPopup}>Play Now</button>
            
            <div className="socialsCont">
                    <a href="https://www.reddit.com/r/Wildguesser/" target="_blank"><img className="socials" src="/footericons/reddit.svg" alt="reddit" draggable="false"/></a>
                    <a href="https://www.instagram.com/wildguesser/" target="_blank"><img className="socials" src="/footericons/instagram.svg" alt="instagram" draggable="false"/></a>
                    <a href="https://www.facebook.com/profile.php?id=61560974100871" target="_blank"><img className="socials" alt="facebook" src="/footericons/facebook.svg" draggable="false"/></a>
                </div>
            </div>
            <div className="navDropdown" onClick={toggleDropdown}>
            <FontAwesomeIcon className="mobileIcon" icon={faBars} />
                    {dropdownVisible && (
                        <div className="navDropdownMenu">
                            <Link to={"/"} className="navDropdownItem">Home</Link>
                            <Link to={"/guide"} className="navDropdownItem">Guide</Link>
                            <Link className="navDropdownItem" to={"/donations"} style={{ textDecoration: 'none' }}>
                                Donate
                            </Link>
                            <button className="dropdownPlay" onClick={openPopup}>Play Now</button>

                            
                        </div>
                    )}
        </div>
        <DifficultyPopup isOpen={isPopupOpen} closeModal={closePopup} />
        </div>
    )

}

export default NavBar;