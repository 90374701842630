import axios from '../axiosConfig';

const fetchData = async (endpoint) => {
    try {
        const response = await axios.get(`/${endpoint}`);
        //console.log(`${endpoint} data:`, response.data);
        if (Array.isArray(response.data)) {
            return response.data.sort();
        }
        return [];
    } catch (error) {
        console.error(`Failed to fetch data from ${endpoint}:`, error);
        return [];
    }
};

export const getContinents = () => fetchData('continents');
export const getHabitatTypes = () => fetchData('habitat_types');
export const getHabitats = () => fetchData('habitats');
export const getDiet = () => fetchData('diet');
export const getFoods = () => fetchData('foods');
export const getGroupBehavior = () => fetchData('group_behavior');
export const getLifestyles = () => fetchData('lifestyles');
export const getColors = () => fetchData('colors');
export const getClass = () => fetchData('taxonomy/class');
export const getOrder = () => fetchData('taxonomy/order');
export const getFamily = () => fetchData('taxonomy/family');
export const getDomesticatedStatus = () => fetchData('domesticated');

export const getRandomAnimal = async (difficulty) => {
    try {
        const url = `/random-animal?difficulty=${difficulty}`;
        //console.log(`Requesting random animal from: ${url}`);
        const response = await axios.get(url, { withCredentials: true });
        //console.log('Response from random animal API:', response);
        return response.data;
    } catch (error) {
        console.error('Error fetching random animal:', error);
        return null;
    }
};

export const checkGuess = async (guessType, guessValue) => {
    try {
        const response = await axios.post('/check-guess', { guessType, guessValue }, { withCredentials: true });
        return response.data.result;
    } catch (error) {
        console.error('Error checking guess:', error);
        throw error;
    }
};

export const getCount = async (countType) => {
    try {
        const response = await axios.get(`/count/${countType}`, { withCredentials: true });
        return response.data.count;
    } catch (error) {
        console.error(`Error fetching ${countType} count:`, error);
        return 0;
    }
};

const cleanText = (text) => {
    // Remove unwanted characters (e.g., special characters that shouldn't be there)
    text = text.replace(/[^\w\s.,;:'"?!-]/g, '');
      
    text = text.replace(/\s+/g, ' ');
    // Trim leading and trailing spaces
    return text.trim();
};

const MAX_CHARACTERS = 600;

export const fetchWikipediaSummary = async (query) => {
    try {
        const response = await axios.get(`/wikipedia/${query}`);
        let { summary, imageUrl } = response.data;

        const paragraphs = summary.split('\n');
        const firstThreeParagraphs = paragraphs.slice(0, 2).map(cleanText).join(' ');

        const trimmedSummary = trimToMaxCharacters(firstThreeParagraphs, MAX_CHARACTERS);

        return { summary: trimmedSummary, imageUrl };
    } catch (error) {
        console.error('Error fetching Wikipedia summary:', error);
        return { summary: '', imageUrl: '' };
    }
};

const trimToMaxCharacters = (text, maxChars) => {
    if (text.length <= maxChars) {
        return text;
    }
    // Find the last space within the limit
    let trimmedText = text.slice(0, maxChars + 1);
    let lastSpaceIndex = trimmedText.lastIndexOf(' ');
    if (lastSpaceIndex > 0) {
        trimmedText = trimmedText.slice(0, lastSpaceIndex);
    }
    return trimmedText + '...';
};

export const fetchWikipediaSummary2 = async (query) => {
    try {
        const response = await axios.get(`/wikipedia/${query}`);
        let { summary, imageUrl } = response.data;

        const paragraphs = summary.split('\n');
        const firstThreeParagraphs = paragraphs.slice(0, 2);
        const formattedSummary = firstThreeParagraphs.join('<br><br>');

        return { summary: formattedSummary, imageUrl };
    } catch (error) {
        console.error('Error fetching Wikipedia summary:', error);
        return { summary: '', imageUrl: '' };
    }
};

export const fetchConservationStatus = async (scientificName) => {
    try {
        const response = await axios.get(`/conservation-status/${scientificName}`);
        return response.data.conservationStatus;
    } catch (error) {
        console.error('Error fetching conservation status:', error);
        return null;
    }
};

const removeLinksAndBoldText = (html) => {
    // Replace opening <a> tags with <b> tags
    let modifiedHtml = html.replace(/<a [^>]*>/g, '<b>');
    // Replace closing </a> tags with </b> tags
    modifiedHtml = modifiedHtml.replace(/<\/a>/g, '</b>');
    return modifiedHtml;
};

const removeUnwantedText = (html) => {
    // Remove [edit] tags
    let modifiedHtml = html.replace(/\[edit\]/g, '');

    // Debug: Log before removing references
    //console.log("Before removing references:", modifiedHtml);

    // Remove all occurrences of [] with their contents (e.g., [3][4])
    modifiedHtml = modifiedHtml.replace(/\[[^\]]*\]/g, '');

    // Additional step to remove <sup> elements with references
    modifiedHtml = modifiedHtml.replace(/<sup[^>]*>.*?<\/sup>/gi, '');

    // Debug: Log after removing references
    //console.log("After removing references:", modifiedHtml);

    // Remove disambiguation notes and similar texts
    const patterns = [
        /For other uses, see .+?\./gi,
        /Several terms? redirect here.+?\./gi,
        /".+?" redirect[s]? here\./gi,
        /This article is about .+?\./gi,
        /It includes all .+?\./gi
    ];

    patterns.forEach(pattern => {
        modifiedHtml = modifiedHtml.replace(pattern, '');
    });

    // Debug: Log after removing disambiguation notes
    //console.log("After removing disambiguation notes:", modifiedHtml);

    modifiedHtml = modifiedHtml.replace(/<div[^>]*class="hatnote[^>]*>[\s\S]*?<\/div>/gi, '');

    // Remove everything after "References", "Bibliography", "External links", "See also", or "Footnotes"
    const unwantedSections = [
        /<h2><span class="mw-headline" id="References">References<\/span>.*<\/h2>[\s\S]*/gi,
        /<h2><span class="mw-headline" id="Bibliography">Bibliography<\/span>.*<\/h2>[\s\S]*/gi,
        /<h2><span class="mw-headline" id="External_links">External links<\/span>.*<\/h2>[\s\S]*/gi,
        /<h2><span class="mw-headline" id="See_also">See also<\/span>.*<\/h2>[\s\S]*/gi,
        /<h2><span class="mw-headline" id="Footnotes">Footnotes<\/span>.*<\/h2>[\s\S]*/gi
    ];

    unwantedSections.forEach(section => {
        modifiedHtml = modifiedHtml.replace(section, '');
    });

    // Debug: Log after removing everything after unwanted sections
    //console.log("Final modified HTML:", modifiedHtml);

    return modifiedHtml;
};

export const fetchWikipediaFullArticle = async (query) => {
    try {
        const response = await axios.get(`/wikipedia/full/${query}`);
        //console.log('Response from Wikipedia API:', response.data);

        if (!response.data || !response.data.fullArticle) {
            throw new Error('Unexpected API response structure');
        }

        let { fullArticle, thumbnail } = response.data.fullArticle;

        if (typeof fullArticle !== 'string') {
            throw new Error('Invalid article format');
        }

        fullArticle = removeLinksAndBoldText(fullArticle); // Process the HTML to remove links
        fullArticle = removeUnwantedText(fullArticle); // Remove unwanted text

        return { fullArticle, thumbnail };
    } catch (error) {
        console.error('Error fetching full Wikipedia article:', error);
        return { fullArticle: '', thumbnail: '' };
    }
};




